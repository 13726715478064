import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Col,
  DatePicker,
  Form,
  message,
  notification,
  Row,
  Select,
  Space,
} from "antd";
import moment from "moment";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { WORK_MODE } from "../../../constants/workModes";
import { isPermission } from "../../../utils/permissionsFunctions";
import { RootState } from "../../../redux/store/store";
import { PERMISSIONS_LIST } from "../../../constants/permissions";
import { MainContext } from "../../../utils/context";
import LicensesService from "../../../services/licenses.service";
import { LicenseItem } from "../../../interfaces/license";
import { getTranslation } from "../../../utils/transaltion";

export default function LicenseModal(props: any) {
  const {
    workModeModal,
    currentRecord,
    createLicense,
    updateLicense,
    formatDate,
    closeLicenseModal,
    saveLoading,
  } = props;
  const globalContext = useContext(MainContext);
  const { t } = useTranslation();
  const [formLicense] = Form.useForm();

  /** current user permissions from redux */
  const permissionsRedux = useSelector(
    (state: RootState) => state.permissionReducer
  );
  const copilotsRedux: any[] = useSelector(
    (state: RootState) => state.copilotReducer
  );
  const orgaRedux: any[] = useSelector(
    (state: RootState) => state.organizationsReducer
  );

  const [licenses, setLicenses] = useState<LicenseItem[]>([]);
  const [copilotsLicenses, setCopilotsLicenses] = useState<LicenseItem[]>([]);
  const [record, setRecord] = useState(
    workModeModal === WORK_MODE.CREATE
      ? {
          start: null,
          expiry: null,
        }
      : currentRecord
  );

  useEffect(() => {
    getLicenses();
  }, [globalContext]);

  useEffect(() => {
    getCopilotsLicenses();
  }, [record?.organization, record?.copilots]);

  const onChangeRecord = (r: any) => {
    const { copilots, organization } = r;
    (organization || copilots) &&
      formLicense.setFieldsValue({ start: null, expiry: null });
    const newRecord = { ...record, ...r };
    setRecord(newRecord);
  };

  /* Handle licenses dates */
  /* get licenses based on selected organization & selected copilots */
  const getCopilotsLicenses = () => {
    const validLicenses: LicenseItem[] = licenses
      /* filter license based on orga, exclude record on update mode to not include its date on calculatiog */
      .filter((license) =>
        workModeModal === WORK_MODE.CREATE
          ? license?.organization?.id === record?.organization?.id
          : license?.organization?.id === record?.organization?.id &&
            license?.id !== currentRecord?.id
      )
      /* get licenses that include all selected copilots */
      .filter((license) => {
        const licCopilotsIds = license?.copilots?.map((cop) => cop?.id);
        const selectedCopilotsIds = record?.copilots?.map(
          (cop: any) => cop?.id
        );
        const intersection = licCopilotsIds?.filter((lic) =>
          selectedCopilotsIds?.includes(lic)
        );
        return intersection?.length > 0;
      });
    setCopilotsLicenses(validLicenses);
  };

  const disableStartLicenseDate = (current: dayjs.Dayjs) => {
    if (!current) return true;
    const today = dayjs().startOf("day");
    // Disable past dates
    if (dayjs(current).isBefore(today)) {
      return true;
    }
    // Disable dates after the record expiry
    if (
      record?.expiry &&
      dayjs(current).isAfter(dayjs(record.expiry).endOf("day"))
    ) {
      return true;
    }
    // Disable dates overlapping with existing licenses
    const isOverlapping = copilotsLicenses?.some(
      (license) =>
        dayjs(current).isBefore(dayjs(license?.expiry).endOf("day")) &&
        dayjs(current).isAfter(dayjs(license?.start).startOf("day"))
    );
    if (isOverlapping) {
      return true;
    }
    return false;
  };

  const disableExpiryLicenseDate = (current: dayjs.Dayjs) => {
    if (!current) return true;
    const today = dayjs().startOf("day");
    // Disable past dates
    if (dayjs(current).isBefore(today)) {
      return true;
    }
    // Disable dates before the record's start date
    if (
      record?.start &&
      dayjs(current).isBefore(dayjs(record.start).startOf("day"))
    ) {
      return true;
    }
    // Disable dates that overlap with existing licenses
    const isOverlapping = copilotsLicenses?.some(
      (license) =>
        dayjs(current).isBefore(dayjs(license?.expiry).endOf("day")) &&
        dayjs(current).isAfter(dayjs(license?.start).startOf("day"))
    );
    if (isOverlapping) {
      return true;
    }
    return false;
  };
  /*... */

  const onFinishCreateUpdate = () => {
    formLicense
      .validateFields()
      .then(() => {
        const isNotValid = copilotsLicenses?.some(
          (license) =>
            dayjs(record?.start).isBefore(
              dayjs(license?.start).add(1, "day").startOf("day")
            ) &&
            dayjs(record?.expiry).isAfter(dayjs(license?.expiry).startOf("day"))
        );
        if (isNotValid) {
          message.error(
            "Please make sure that license dates are not overlapping with existing licenses"
          );
        } else {
          if (workModeModal === WORK_MODE.CREATE) {
            createLicense(record);
          } else if (workModeModal === WORK_MODE.UPDATE) {
            updateLicense(record?.id, record);
          }
        }
      })
      .catch((err: any) => {
        message.warning(t("errors:REQUIRED_FIELDS"));
      });
  };

  if (!globalContext) {
    return <></>;
  }
  const licensesServices = new LicensesService(
    globalContext.context,
    globalContext.setContext,
    ""
  );
  const getLicenses = () => {
    const nowDate = new Date();
    licensesServices
      .filterResource({
        offset: 0,
        limit: 1000,
        filter: {
          rule: {
            operator: "and",
            value: [
              {
                operator: ">=",
                field: "expiry",
                value: nowDate?.toISOString(),
              },
            ],
          },
        },
      })
      .then((result: any) => {
        setLicenses(result?.items);
      })
      .catch((err: any) => {
        if (err?.code === "ERR_NETWORK") {
          notification.error({
            message: t("errors:NETWORK_ERROR"),
            placement: "bottomRight",
          });
        }
      });
  };

  return (
    <>
      <Form
        form={formLicense}
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 18 }}
        name="wrap"
        labelAlign="left"
        labelWrap
        initialValues={{
          start: record?.start ? dayjs(record?.start) : null,
          expiry: record?.expiry ? dayjs(record?.expiry) : null,
          copilots: record?.copilots?.map((value: any) => value?.id),
          organization: record?.organization?.id,
        }}
      >
        <Row>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label={"Organization"}
              name="organization"
              rules={[{ required: true }]}
            >
              <Select
                disabled={workModeModal !== WORK_MODE.CREATE}
                options={orgaRedux?.map((orga: any) => {
                  return { label: orga?.name, value: orga?.id };
                })}
                onChange={(value) => {
                  onChangeRecord({
                    organization: { id: value },
                    start: null,
                    expiry: null,
                  });
                }}
                showSearch
                filterOption={(input, option) =>
                  option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                value={record?.organization?.id}
                style={{ width: "95%" }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label={"Copilots"}
              name="copilots"
              rules={[{ required: true }]}
            >
              <Select
                options={copilotsRedux?.map((copilot) => {
                  return {
                    label: getTranslation(copilot?.name, "COPILOTS_"),
                    value: copilot?.id,
                  };
                })}
                mode="multiple"
                disabled={
                  workModeModal === WORK_MODE.VIEW ||
                  (workModeModal === WORK_MODE.UPDATE &&
                    moment(currentRecord?.start)
                      .startOf("day")
                      .isBefore(moment().endOf("day")))
                }
                onChange={(values: any[]) => {
                  onChangeRecord({
                    copilots: values?.map((value) => {
                      return { id: value };
                    }),
                    start: null,
                    expiry: null,
                  });
                }}
                value={record?.copilots?.map((value: any) => value?.id)}
                style={{ width: "95%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label={"Start date"}
              name="start"
              rules={[{ required: true }]}
            >
              <DatePicker
                disabled={
                  !record?.organization ||
                  !record?.copilots ||
                  (workModeModal === WORK_MODE.UPDATE &&
                    moment(currentRecord?.start)
                      .startOf("day")
                      .isBefore(moment().endOf("day"))) ||
                  workModeModal === WORK_MODE.VIEW
                }
                style={{ width: "95%" }}
                disabledDate={(current) => {
                  return disableStartLicenseDate(current);
                }}
                format={formatDate}
                onChange={(value) => {
                  onChangeRecord({ start: value ? value.toISOString() : null });
                }}
                value={record.start ? dayjs(record.start) : null}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label={"Expiry date"}
              name="expiry"
              rules={[{ required: true }]}
            >
              <DatePicker
                disabled={
                  !record.start ||
                  (workModeModal === WORK_MODE.UPDATE &&
                    moment(currentRecord?.expiry)
                      .endOf("day")
                      .isBefore(moment().endOf("day"))) ||
                  workModeModal === WORK_MODE.VIEW
                }
                style={{ width: "95%" }}
                disabledDate={(current) => {
                  return disableExpiryLicenseDate(current);
                }}
                format={formatDate}
                onChange={(value) => {
                  onChangeRecord({
                    expiry: value ? value.toISOString() : null,
                  });
                }}
                value={record.expiry ? dayjs(record.expiry) : null}
              />
            </Form.Item>
          </Col>
        </Row>
        {isPermission(permissionsRedux, PERMISSIONS_LIST.ORG_ADMIN) && (
          <Row justify="end">
            <Space>
              <Button
                className="cancel-button"
                onClick={() => {
                  closeLicenseModal();
                }}
              >
                {getTranslation("CANCEL", "lables")}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="save-button"
                onClick={() => {
                  onFinishCreateUpdate();
                }}
                loading={saveLoading}
                disabled={
                  !record.start ||
                  (workModeModal === WORK_MODE.UPDATE &&
                    moment(currentRecord?.expiry)
                      .endOf("day")
                      .isBefore(moment().endOf("day"))) ||
                  workModeModal === WORK_MODE.VIEW
                }
              >
                {getTranslation("SAVE", "labels")}
              </Button>
            </Space>
          </Row>
        )}
      </Form>
    </>
  );
}
