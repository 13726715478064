import { useContext, useEffect, useRef, useState } from "react";
import { MainContext } from "../../../../../utils/context";
import { Modal, Spin } from "antd";
import { ActionItem } from "../../../../../interfaces/actions";
import type { ActionType } from "@ant-design/pro-components";
import { ProTable } from "@ant-design/pro-components";
import AddActionModal from "./AddActionModal";
import { PAGES } from "../../../../../constants/pages";
import { ActionsTableDef } from "../columnDef/ActionsTable.def";
import { SharedContext } from "../../context/sharedContext";
import {
  SharedContextFunctionsInterface,
  useSharedContextFunctions,
} from "../logic/ActionLogic";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { WORK_MODE } from "../../../../../constants/workModes";
import { validate as uuidValidate } from "uuid";

const ActionsComponent: React.FC = () => {
  const { t } = useTranslation();
  const globalContext = useContext(MainContext);
  const { uuidaction } = useParams<{ uuidaction?: string }>();
  const navigate = useNavigate();
  /* enums for the filters */
  const [valueEnumPredefinedAction, setValueEnumPredefinedAction] = useState<{
    [key: string]: { text: string };
  }>({});
  const [valueEnumPredefinedRisk, setValueEnumPredefinedRisk] = useState<{
    [key: string]: { text: string };
  }>({});
  const actionRef = useRef<ActionType>();
  const {
    closeActionModal,
    getEnums,
    filterListeActions,
    updateAction,
    showActionModal,
  } = useSharedContextFunctions() as SharedContextFunctionsInterface;
  const {
    openActionModal,
    actionRecord,
    setActionRecord,
    workModeAction,
    setWorkModeAction,
    totalActions,
    getActionsLoading,
    actionData,
    setActionData,
  } = useContext(SharedContext);

  useEffect(() => {
    if (!globalContext || !globalContext.context.orga) {
      return;
    }

    getEnums(setValueEnumPredefinedAction, setValueEnumPredefinedRisk);
  }, [globalContext]);
  /** handle access with url */
  useEffect(() => {
    if (uuidaction && uuidValidate(uuidaction)) {
      showActionModal();
      setActionRecord(actionData?.find((obj: any) => obj?.id === uuidaction));
      setWorkModeAction(WORK_MODE.VIEW);
    }
    if (uuidaction && !uuidValidate(uuidaction)) {
      navigate("/error", {
        state: {
          subTitle: "Something went wrong ,Invalid Url.",
        },
      });
    }
  }, [uuidaction, actionData]);
  if (!globalContext) {
    return <></>;
  }
  /** columns def */
  const columns = ActionsTableDef(
    valueEnumPredefinedRisk,
    valueEnumPredefinedAction
  );

  return (
    <>
      <Spin spinning={getActionsLoading} size="large" tip="Loading...">
        <ProTable<ActionItem>
          columns={columns}
          actionRef={actionRef}
          cardBordered
          dataSource={actionData}
          request={(params = {}): any => {
            const { name, risk, model, current, pageSize } = params;
            filterListeActions(
              {
                current,
                pageSize,
              },
              {
                name: { value: name, operator: "HAS" },
                risk,
                model,
              }
            );

            return;
          }}
          editable={{
            type: "multiple",
          }}
          columnsState={{
            persistenceKey: "pro-table-singe-demos",
            persistenceType: "localStorage",
          }}
          rowKey="id"
          search={{
            span: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6, xxl: 6 },
            searchText: t("SEARCH_TEXT_BUTTON"),
            resetText: t("RESET_TEXT_BUTTON"),
            labelWidth: "auto",
            className: "custom-search-form",
          }}
          options={{
            setting: {
              listsHeight: 400,
            },
          }}
          form={{
            syncToUrl: (values, type) => {
              if (type === "get") {
                return {
                  ...values,
                  created_at: [values.startTime, values.endTime],
                };
              }
              return values;
            },
          }}
          pagination={{
            pageSize: 20,
            total: totalActions,
            onChange(page, pageSize) {},
          }}
          dateFormatter="string"
          headerTitle={t("ACTIONS")}
        />
      </Spin>

      <Modal
        destroyOnClose={true}
        title={t("ACTION_CONFIGURATION")}
        open={openActionModal}
        onCancel={closeActionModal}
        width={1200}
        footer={null}
      >
        <AddActionModal
          closeActionModal={closeActionModal}
          workModeAction={workModeAction}
          riskActions={actionData}
          setRiskActions={setActionData}
          actionRecord={actionRecord}
          setWorkModeAction={setWorkModeAction}
          setActionRecord={setActionRecord}
          updateSource={PAGES.ACTIONS}
          updateAction={updateAction}
        />
      </Modal>
    </>
  );
};
export default ActionsComponent;
