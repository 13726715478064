import { STATUS } from "../constants/status";
import { NEGATIVE_ATTRIBUTE } from "../constants/negativeValues";
import { DefaultOptionType } from "antd/es/select";
import { ActionSettingsItem } from "../interfaces/actionSettings";
import { createKey, getTranslation } from "./transaltion";
import { languages } from "../constants/languages";
import { NavigateFunction } from "react-router-dom";
import { validate as uuidValidate } from "uuid";
interface NestedObject {
  [key: string]: any;
}

export interface FilteredData {
  offset: number;
  limit: number;
  filter?: {
    rule: {
      operator: string;
      value: FilterRule[];
    };
  };
}

interface FilterRule {
  operator: string;
  field?: string;
  value: any;
}

export function deepEqual(
  object1: NestedObject,
  object2: NestedObject
): boolean {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];

    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }

  return true;
}

function isObject(object: any): object is NestedObject {
  return object != null && typeof object === "object";
}

export const toLowerCase = (label: string) => {
  return (label.charAt(0) + label.slice(1).toLowerCase())?.replace(/_/g, " ");
};
//

export function cleanObject(obj: { [key: string]: any }): any {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];

      // Check if the value is empty, null, or undefined and remove the field
      if (value === "" || value === null || value === undefined) {
        delete obj[key];
      }
    }
  }

  return obj;
}

export function getTypeByNameForRules(
  attributes: any[],
  name: string
): string | null {
  for (const attribute of attributes) {
    if (attribute.name === name) {
      return attribute.type;
    }
  }
  return null; // Return null if the name is not found
}

export const modelIdsToRules = (
  ids: string[]
): { operator: string; field: string; value: string }[] => {
  return ids.map((id) => ({
    operator: "=",
    field: "model",
    value: id,
  }));
};

export function currentActionFunction(risk: any): any {
  // sort actions by priority
  const sortedActions = risk.actions?.sort((a: any, b: any) => {
    return a.action_conf?.priority - b.action_conf?.priority;
  });
  sortedActions?.forEach((action: any) => {
    action.executions?.sort((a: any, b: any) => {
      return a?.execution_conf?.priority - b?.execution_conf?.priority;
    });
    // reverse the ordre of the contexts to always take the last one inserted
    if (action.contexts && action.contexts.length > 1) {
      action.contexts.reverse();
    }
  });

  // Find the first action with status 'WAIT_FOR_EXECUTION'
  const waitForExecutionAction = sortedActions?.find(
    (action: any) => action.status === STATUS.WAIT_FOR_EXECUTION
  );
  if (waitForExecutionAction) {
    return waitForExecutionAction;
  }
  // Check if at least one  action has status 'OPEN'
  const isOpen = sortedActions?.some((action: any) => action.status === "OPEN");
  if (isOpen) {
    const firstOpenAction = sortedActions?.find(
      (action: any) => action.status === "OPEN"
    );
    return firstOpenAction;
  }
}

export function checkAlphanumericType(inputString: any) {
  // const alphanumericRegex = /^[a-zA-Z0-9é\s-]+$/;
  const alphanumericRegex = /^(?=.*[a-zA-Zé\s-])[a-zA-Z0-9é\s-]+$/;

  const numericRegex = /^[\d,.€]+$/;
  if (alphanumericRegex.test(inputString)) {
    return "left";
  } else if (numericRegex.test(inputString)) {
    return "right";
  } else {
    return "neither";
  }
}

export const isNegative = (value: any) => {
  return value < 0;
};

export const getCopilotId = (copilotList: any, copilotName: any) => {
  const foundCopilot = copilotList.find(
    (copilot: any) => copilot.name === copilotName
  );
  return foundCopilot ? foundCopilot.id : null;
};

export const getActionType = (PRedefinedActions: any, idModelAction: any) => {
  const foundActionTypeName = PRedefinedActions.find(
    (action: any) => action.id === idModelAction
  );
  return foundActionTypeName ? foundActionTypeName : null;
};

// export const isDate = (value: string): boolean => {
//   const dateRegex =
//     /^[A-Za-z]{3}, \d{2} [A-Za-z]{3} \d{4} \d{2}:\d{2}:\d{2} [A-Z]{3}$/;
//   const isoDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\+\d{2}:\d{2}$/;

//   if (dateRegex.test(value)) {
//     return true;
//   } else if (isoDateRegex.test(value)) {
//     return true;
//   } else {
//     return false;
//   }
// };
export const isDate = (value: string): boolean => {
  const dateRegex =
    /^[A-Za-z]{3}, \d{2} [A-Za-z]{3} \d{4} \d{2}:\d{2}:\d{2} [A-Z]{3}$/;
  const isoDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\+\d{2}:\d{2}$/;
  const mmddyyyyRegex =
    /^(0?[1-9]|1[0-2])\/(0?[1-9]|[12][0-9]|3[01])\/(\d{4})$/;

  return (
    dateRegex.test(value) ||
    isoDateRegex.test(value) ||
    mmddyyyyRegex.test(value)
  );
};

export const selectFilterOption = (
  input: string,
  option: DefaultOptionType | undefined
) => {
  return (
    option?.props.children
      .toString()
      .toLowerCase()
      .includes(input.toLowerCase()) ||
    option?.props.value.toString().toLowerCase().includes(input.toLowerCase())
  );
};

export const findCurrentObj = (list: any[], value: any) => {
  return list.find((obj) => obj.id === value);
};

export const getCanAuto = (PRedefinedList: any, id: any) => {
  const CanAuto = PRedefinedList.find((item: any) => item.id === id);

  if (CanAuto && CanAuto.canAuto === true) {
    return true;
  } else {
    return false;
  }
};

export function checkAttributesValidity(
  obj: Record<string, any>
): { status: number; message: string }[] {
  const errors = [];
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (obj[key] === undefined || obj[key] === null || obj[key] === "") {
        errors.push({
          status: 400,
          message: `Error processing, '${key}' data missing or invalid`,
        });
      }
    }
  }
  return errors;
}

export const listToLowerCase = (res: any[]) => {
  return res.forEach((item: any) => {
    item.name = item.name
      .toLowerCase()
      .replace(/_/g, " ")
      .replace(/^\w/, (c: any) => c.toUpperCase());
  });
};

export const constructActionSettingsObject = (inputObject: any) => {
  const result: ActionSettingsItem = {};
  const updatedInputObject: {
    actionParameters: ActionSettingsItem;
    [key: string]: any;
  } = {
    ...inputObject,
    actionParameters: result,
  };

  for (const key in inputObject) {
    if (key.startsWith("Name")) {
      const index = key.slice(4);
      const name = inputObject[key] as string;
      const typeKey = `Type${index}`;
      const valueKey = `Value${index}`;

      if (inputObject[typeKey] && inputObject[valueKey]) {
        const type = inputObject[typeKey] as string;
        const defaultValue = inputObject[valueKey] as string | number;

        result[name] = {
          name,
          type,
          defaultValue,
        };
        delete updatedInputObject[key];
        delete updatedInputObject[typeKey];
        delete updatedInputObject[valueKey];
      }
    }
  }

  return updatedInputObject;
};

export const constructActionParametersObject = (inputObject: any) => {
  const reconstructedObject: any = {
    parameters: {},
  };

  inputObject.forEach((item: any) => {
    const { name, type, defaultValue, suffix, choice } = item;
    reconstructedObject.parameters[suffix] = {
      name,
      type,
      defaultValue,
      choice: !choice || choice === false ? false : true,
    };
  });

  return reconstructedObject;
};

export const filterProperties = (input: any): any => {
  const filteredObject: any = { ...input };
  Object.keys(filteredObject).forEach((key) => {
    if (
      key.startsWith("Name") ||
      key.startsWith("Type") ||
      key.startsWith("Value")
    ) {
      delete filteredObject[key];
    }
  });
  return filteredObject;
};

export const isNameInUse = (valuesNames: any, record: any, value: string) => {
  // Exclude the name from the record in the validation
  const filteredDataNameValues = valuesNames.filter(
    (name: any) => name !== record?.name
  );

  return filteredDataNameValues.includes(createKey(value));
};

export const getLanguageName = (code: string): string | undefined => {
  for (const [key, value] of Object.entries(languages)) {
    if (key === code) {
      return value;
    }
  }
  return undefined;
};

export const updateURL = (
  currentPath: string,
  navigate: NavigateFunction,
  type: "execution" | "action",
  uuid?: string
) => {
  if (uuid) {
    // Append '/type/id' to the current path
    const newPath = `${currentPath}/${type}/${uuid}`;
    navigate(newPath, { replace: true });
  } else {
    // Find the position of '/type/' in the path, if it exists
    const typeIndex = currentPath.indexOf(`/${type}/`);
    const newPath =
      typeIndex !== -1 ? currentPath.slice(0, typeIndex) : currentPath;
    navigate(newPath, { replace: true });
  }
};

export const navigateOnInvalidUUID = (
  navigate: NavigateFunction,
  uuid: string | undefined
) => {
  if (uuid && !uuidValidate(uuid)) {
    navigate("/error", {
      state: {
        subTitle: getTranslation("INVALID_URL", "errors"),
      },
    });
  }
};
