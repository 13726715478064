import React from "react";
/* antd */
import { Image, Layout, theme } from "antd";
/* react-icons */
/* assets */
import styles from "../../../assets/style/css/MainLayout.module.css";
import UserProfile from "../dropdown/UserProfile";
import { PiBell } from "react-icons/pi";
import { ExoBrainIcon } from "../../../assets/images";

const { Header } = Layout;

const HeaderLayout: React.FC = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Header
      className={styles.navbar}
      style={{
        position: "fixed",
        width: "100%",
        height: "70px",
        zIndex: 10,
        backgroundColor: colorBgContainer,
        display: "flex",
        alignItems: "start",
      }}
    >
      {/* Logo */}
      <h2
        className={styles.logo}
        style={{
          pointerEvents: "none",
          width: "80px",
          backgroundColor: "#0056A6",
          height: "100%",
          display: "flex",
          alignItems: "center",
          padding: "16px",
          marginInlineStart: "-50px",
        }}
      >
        <span
          style={{
            width: "48px",
            height: "48px",
            backgroundColor: "#fff",
            borderRadius: "8px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Image
            width={46}
            height={46}
            src={ExoBrainIcon}
            style={{
              marginBlockStart: "-24px",
              pointerEvents: "none",
              cursor: "pointer",
            }}
          />
        </span>
      </h2>

      {/* Navbar right elements */}
      <div>
        {/* Avatar */}
        <div
          className={styles.avatar}
          style={{
            direction: "ltr",
            paddingInline: "20px",
            height: "44px",
            width: "44px",
            marginBlockStart: "12px",
            background: "linear-gradient(to bottom right, #FF0019, #FFA723)",
            borderRadius: "8px",
          }}
        >
          <span className={styles.NotifsIcon}>
            <PiBell
              size={24}
              style={{
                float: "left",
                marginInlineStart: "-10px",
              }}
            />
          </span>
        </div>

        {/* Avatar */}
        <div
          style={{
            direction: "ltr",
            backgroundColor: "#f0f0f0",
            borderRadius: "8px",
            height: "44px",
            marginInlineStart: "10px",
            marginBlockStart: "12px",
            paddingInlineEnd: "8px",
          }}
        >
          <UserProfile />
        </div>
      </div>
    </Header>
  );
};

export default HeaderLayout;
