import { useRef } from "react";
import { ActionType, ProColumns, ProTable } from "@ant-design/pro-components";
import { Button, Spin } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { t } from "i18next";
import { useSelector } from "react-redux";

import { LicenseItem } from "../../../../interfaces/license";
import LicensesColumns from "./LicensesColumns";
import { RootState } from "../../../../redux/store/store";
import { PAGES_SIZE } from "../../../../constants/pagination";
import { getTranslation } from "../../../../utils/transaltion";

export default function LicensesTable(props: any) {
  const {
    rows,
    total,
    getLoading,
    filterLicenses,
    deleteLicense,
    createLicenseModal,
    updateLicenseModal,
    viewLicenseModal,
    formatDate,
  } = props;

  const copilotsRedux: any[] = useSelector(
    (state: RootState) => state.copilotReducer
  );
  const orgaRedux: any[] = useSelector(
    (state: RootState) => state.organizationsReducer
  );
  const copilotsEnum: { [key: string]: { text: string } } = {};
  copilotsRedux?.forEach((curr: any) => {
    copilotsEnum[curr.id] = { text: getTranslation(curr.name, "COPILOTS_") };
  });
  const orgaEnum: { [key: string]: { text: string } } = {};
  orgaRedux?.forEach((curr: any) => {
    orgaEnum[curr.id] = { text: curr.name };
  });

  const actionRef = useRef<ActionType>();
  const columns: ProColumns<LicenseItem>[] = LicensesColumns(
    formatDate,
    viewLicenseModal,
    updateLicenseModal,
    deleteLicense,
    copilotsEnum,
    orgaEnum
  );

  return (
    <Spin spinning={getLoading} size="large" tip="Loading...">
      <ProTable<LicenseItem>
        dataSource={rows}
        columns={columns}
        actionRef={actionRef}
        cardBordered
        editable={{
          type: "multiple",
        }}
        columnsState={{
          persistenceKey: "pro-table-singe-demos",
          persistenceType: "localStorage",
        }}
        rowKey="id"
        search={{
          span: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6, xxl: 6 },
          labelWidth: "auto",
          searchText: t("SEARCH_TEXT_BUTTON"),
          resetText: t("RESET_TEXT_BUTTON"),
          className: "custom-search-form",
        }}
        options={{
          setting: {
            listsHeight: 400,
          },
        }}
        pagination={{
          pageSize: PAGES_SIZE,
          total: total,
          onChange(page, pageSize) {
            filterLicenses({ current: page, pageSize });
          },
        }}
        request={(params): any => {
          const { organization, start, expiry, copilot, current, pageSize } =
            params;
          filterLicenses(
            {
              current,
              pageSize,
            },
            {
              start: { value: start, operator: "range" },
              expiry: { value: expiry, operator: "range" },
              copilot,
              organization,
            }
          );
        }}
        toolBarRender={() => [
          <Button
            key="add_license"
            type="primary"
            className="button-add-risk"
            icon={<PlusOutlined />}
            onClick={() => createLicenseModal()}
          >
            {getTranslation("ADD_LICENSE", "")}
          </Button>,
        ]}
        form={{
          syncToUrl: (values: any) => {
            //filterLicenses(values.current);
            return values;
          },
        }}
        dateFormatter="string"
        headerTitle={getTranslation("LICENSES", "labels")}
        className="custom-table-cell"
      />
    </Spin>
  );
}
