export const languages = {
  "en-US": "English",
  "fr-FR": "French",
  "ar-DZ": "Arabic",
};
export const LanguagesEnum = {
  "en-US": { text: "English" },
  "fr-FR": { text: "French" },
  "ar-DZ": { text: "Arabic" },
};
