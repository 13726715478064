/* eslint-disable jsx-a11y/anchor-is-valid */
import { useRef, useContext, useEffect, useState } from "react";
import { MainContext } from "../../../../utils/context";
/* AntD */
import type { ActionType, ProColumns } from "@ant-design/pro-components";
import { ProTable } from "@ant-design/pro-components";
import { Modal, Spin, message } from "antd";
/* Services */
import ExecutionsService from "../../../../services/execution-confs.service";
/* Components */
import AddExecutionModal from "./AddExecutionModal";
import MenuOptions from "../../../common/menu/MenuOptions";
/* Global Vars */
import { ExecutionInterface } from "../../../../interfaces/execution";
import { PAGES } from "../../../../constants/pages";
import { WORK_MODE } from "../../../../constants/workModes";
/** redux */
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store/store";
import { useTranslation } from "react-i18next";
import { createKey, getTranslation } from "../../../../utils/transaltion";
import ActionsService from "../../../../services/action-confs.service";
import handleApiError from "../../../../services/functions/handle-api-errors/handleApiError";
import { REQUEST } from "../../../../services/functions/handle-api-errors/const";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { validate as uuidValidate } from "uuid";

function ExecutionsTable() {
  const globalContext = useContext(MainContext);
  const { t } = useTranslation();
  /* Protable */
  const actionRef = useRef<ActionType>();
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState<any>(0);
  const [getExecutionsLoading, setGetExecutionsLoading] = useState(false);
  const location = useLocation();
  const { uuidexecution } = useParams<{ uuidexecution?: string }>();
  const navigate = useNavigate();
  const updateURL = (uuidExecution: string) => {
    const currentPath = location.pathname; // Current URL path
    const newPath = `${currentPath}/execution/${uuidExecution}`; // Append '/action/id'
    // Replace the current URL without navigating
    navigate(newPath, { replace: true });
  };
  /* Execution Modal */
  const [openExecutionModal, setOpenExecutionModal] = useState(false);
  const [executionRecord, setExecutionRecord] = useState<any>([]);
  const [workModeExecution, setWorkModeExecution] = useState("");

  /** get predefined from redux */
  const predefinedExecutions = useSelector(
    (state: RootState) => state.predefinedExecutionOrgaReducer
  );
  /** enum declarations*/
  const [valueEnumPredefinedExecutions, setValueEnumPredefinedExecutions] =
    useState<{
      [key: string]: { text: string };
    }>({});
  const [valueEnumActions, setValueEnumActions] = useState<{
    [key: string]: { text: string };
  }>({});

  const columns: ProColumns<ExecutionInterface>[] = [
    {
      title: t("EXECUTION"),
      dataIndex: "name",
      valueType: "text",
    },
    {
      title: t("ACTION"),
      dataIndex: "action",
      valueType: "select",
      valueEnum: valueEnumActions,
      fieldProps: {
        showSearch: true, // Enable search in filter
        optionFilterProp: "label", // Search by label
        filterOption: (input: any, option: any) =>
          option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0,
      },
      render: (text, record) => <span>{record?.action?.name}</span>,
    },
    {
      title: t("EXECUTION_PREDEFINED"),
      dataIndex: "model",
      valueType: "select",
      valueEnum: valueEnumPredefinedExecutions,
      fieldProps: {
        showSearch: true, // Enable search in filter
        optionFilterProp: "label", // Search by label
        filterOption: (input: any, option: any) =>
          option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0,
      },
      search: {
        transform: (value) => {
          return {
            model: {
              value: getPRedefinedExecutionIdsByName(value),
              operator: "OR",
            },
          };
        },
      },
      render: (text, record) => {
        return getTranslation(record?.model?.name || "", "data");
      },
    },
    {
      title: t("MORE_ACTIONS"),
      valueType: "option",
      key: "option",
      width: "20%",
      align: "center",
      render: (text, record) => (
        <span>
          <MenuOptions
            viewRecord={() => {
              updateURL(record?.id);
              setRecord(record);
              changeWorkMode(WORK_MODE.VIEW);
              showExecutionModal();
            }}
            editRecord={() => {
              updateURL(record?.id);
              setRecord(record);
              changeWorkMode(WORK_MODE.UPDATE);
              showExecutionModal();
            }}
            deleteRecord={() => {
              deleteExecution(record.id);
            }}
            title={t("errors:CONFIRM_DELETE_MESSAGE")}
          />
        </span>
      ),
    },
  ];

  useEffect(() => {
    if (!globalContext || !globalContext.context.orga) {
      return;
    }
    getEnums();
  }, [globalContext]);
  /** handle access with url */
  useEffect(() => {
    if (uuidexecution && uuidValidate(uuidexecution)) {
      setOpenExecutionModal(true);

      setExecutionRecord(rows?.find((obj: any) => obj?.id === uuidexecution));

      setWorkModeExecution(WORK_MODE.VIEW);
    }
    if (uuidexecution && !uuidValidate(uuidexecution)) {
      navigate("/error", {
        state: {
          subTitle: "Something went wrong ,Invalid Url.",
        },
      });
    }
  }, [uuidexecution, rows]);
  /* Services  */
  if (!globalContext) {
    return <></>;
  }

  const executionsServices = new ExecutionsService(
    globalContext.context,
    globalContext.setContext,
    ""
  );
  const actionsServices = new ActionsService(
    globalContext.context,
    globalContext.setContext
  );
  /**construct enums  */
  const getEnums = async () => {
    // predefined risk enum
    const updatedEnumPredefinedExecution: { [key: string]: { text: string } } =
      {};
    const nameSet: Set<string> = new Set(); // Set to store unique curr.name values
    predefinedExecutions?.forEach((curr: any) => {
      // Check if curr.name is not already in the Set
      if (!nameSet.has(curr.name)) {
        updatedEnumPredefinedExecution[curr.name] = {
          text: getTranslation(curr.name, "data"),
        };
        nameSet.add(curr.name);
      }
    });
    setValueEnumPredefinedExecutions(updatedEnumPredefinedExecution);
    await actionsServices
      .getResources({ pageSize: 20 }, ["risk", "model", "executions"])
      .then((res: any) => {
        const updatedEnumPredefinedActions: {
          [key: string]: { text: string };
        } = {};
        res?.items?.forEach((curr: any) => {
          updatedEnumPredefinedActions[curr.id] = {
            text: curr.name,
          };
        });
        setValueEnumActions(updatedEnumPredefinedActions);
      })
      .catch((err: any) => {
        handleApiError(err);
      });
  };

  const updateExecution = async (execution: any) => {
    let execution_ = JSON.parse(JSON.stringify(execution));
    delete execution_["key"];
    delete execution_["model"];
    execution_["reasons"] = execution_.reasons?.map((reason: any) => reason.id);
    execution_["roles"] = execution_.roles?.map((role: any) => role.id);

    await executionsServices
      .updateResource(executionRecord?.id, execution_)
      .catch((err) => {
        handleApiError(err, REQUEST.UPDATE);
      });
  };

  const deleteExecution = async (id: string) => {
    await executionsServices
      .deleteResource(id)
      .then((res) => {
        message.success("Execution successfully deleted");
        filterListeExecutions({ current: 1, pageSize: 20 });
      })
      .catch((err) => {
        handleApiError(err, REQUEST.DELETE);
      });
  };

  /**   Execution modal treatement  **/
  const changeWorkMode = (workMode: string) => {
    setWorkModeExecution(workMode);
  };

  const showExecutionModal = () => {
    setOpenExecutionModal(true);
  };

  const closeExecutionModal = () => {
    const currentPath = location.pathname; // Current URL path
    // Find the position of '/execution/' in the path, if it exists
    const executionIndex = currentPath.indexOf("/execution/");
    // If '/action/' exists, slice the path up to '/action/', else use the full current path
    const newPath =
      executionIndex !== -1
        ? currentPath.slice(0, executionIndex)
        : currentPath;
    // Replace the current URL without navigating
    navigate(newPath, { replace: true });

    setOpenExecutionModal(false);
  };

  const setRecord = (record: any) => {
    setExecutionRecord(record);
  };
  const getPRedefinedExecutionIdsByName = (execName: string) => {
    const filteredIds: any[] = [];
    predefinedExecutions?.forEach((exec: any) => {
      if (exec?.name?.includes(createKey(execName))) {
        filteredIds?.push(exec?.id);
      }
    });
    return filteredIds;
  };
  /**filter */
  const filterListeExecutions = async (params: any, filters?: any) => {
    setGetExecutionsLoading(true);
    let filters_ = JSON.parse(JSON.stringify(filters));

    await executionsServices
      .filterResource(params, filters_, ["action", "model"])
      .then((response: any) => {
        setGetExecutionsLoading(false);
        setRows(response?.items);
        setTotal(response?.total);
      })
      .catch((err: any) => {
        setGetExecutionsLoading(false);
        handleApiError(err);
      });
  };

  return (
    <>
      <Spin spinning={getExecutionsLoading} size="large" tip="Loading...">
        <ProTable<ExecutionInterface>
          dataSource={rows}
          columns={columns}
          actionRef={actionRef}
          cardBordered
          editable={{
            type: "multiple",
          }}
          columnsState={{
            persistenceKey: "pro-table-singe-demos",
            persistenceType: "localStorage",
          }}
          rowKey="id"
          search={{
            span: { xs: 24, sm: 12, md: 8, lg: 8, xl: 8, xxl: 8 },
            labelWidth: "auto",
            searchText: t("SEARCH_TEXT_BUTTON"),
            resetText: t("RESET_TEXT_BUTTON"),
            className: "custom-search-form",
          }}
          options={{
            setting: {
              listsHeight: 400,
            },
          }}
          form={{
            syncToUrl: (values, type) => {
              if (type === "get") {
                return {
                  ...values,
                  created_at: [values.startTime, values.endTime],
                };
              }
              return values;
            },
          }}
          pagination={{
            pageSize: 20,
            total: total,
            onChange(page, pageSize) {},
          }}
          request={(params = {}, sort, filter): any => {
            const { name, action, model, current, pageSize } = params;
            filterListeExecutions(
              {
                current,
                pageSize,
              },
              {
                name: { value: name, operator: "HAS" },
                action,
                model,
              }
            );

            return;
          }}
          dateFormatter="string"
          headerTitle={t("EXECUTIONS")}
        />
      </Spin>

      {/* ADD Execution Modal */}
      <Modal
        destroyOnClose={true}
        title={t("EXECUTION_CONFIGURATION")}
        open={openExecutionModal}
        onCancel={closeExecutionModal}
        width={1000}
        footer={null}
      >
        <AddExecutionModal
          closeExecutionModal={closeExecutionModal}
          actionExecutions={rows}
          setActionExecutions={setRows}
          workModeExecution={workModeExecution}
          executionRecord={executionRecord}
          updateSource={PAGES.EXECUTIONS}
          setWorkModeExecution={setWorkModeExecution}
          updateExecution={updateExecution}
        />
      </Modal>
    </>
  );
}
export default ExecutionsTable;
