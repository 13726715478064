import { ReloadOutlined } from "@ant-design/icons";
import { Badge, Button, List, Spin, Tooltip } from "antd";
import { ProFormInstance } from "@ant-design/pro-components";
import { RisksItem } from "../../../../interfaces/risks";
import { createKey, getTranslation } from "../../../../utils/transaltion";
import SortingMenu from "../menus/SortingMenu";
import { useContext, useEffect, useState } from "react";
import { SharedContext } from "../context/SharedContext";
import { useSelector } from "react-redux";
import { Sortingtypes } from "../../../../constants/sortingTypes";
import { RootState } from "../../../../redux/store/store";
import {
  SharedContextFunctions,
  useSharedContextFunctions,
} from "../logic/runningShared.logic";
import React from "react";
import VirtualList from "rc-virtual-list";
import { getCopilotId, isDate } from "../../../../utils/functions";
import { FilterButton } from "./filters/FilterButton";
import { RunningHistoryVirtualListTitle } from "./risk-history/RunningHistoryVirtualListTitle";
import { RunningHistoryVirtualListDescription } from "./risk-history/RunningHistoryVirtualListDescription";
import { RUNNING_MENU } from "../../../../constants/riskMenus";
import { RunningOpenActionsVirtualListDescription } from "./open-actions/RunningOpenActionsVirtualListDescription";
import { RunningOpenActionsVirtualListTitle } from "./open-actions/RunningOpenActionsVirtualListTitle";
import { Fulfillement_Risk } from "../data/fulfillment";
import { Supply_Matching_Risk } from "../data/supplyMatching";
import { Demand_Matching_Risk } from "../data/demandMatching";
import { Inbound_Freight_Risk } from "../data/inboundFreight";
import { DATA_NEEDED_TYPE } from "../../../../constants/DataNeededTypes";

export const RunningsVirtualList = (props: any) => {
  const {
    copilotName,
    menu,
    currentActionId,
    appendData,
    handleRowClick,
    isFirstLoad,
    filterparams,
    setFilterparams,
    setSortingOrder,
    setAcceptRejectState,
    setLoading,
    setSortingField,
    setIsSecondColumnOpen,
  } = props;

  const [sortingMenuActive, setSortingMenuActive] = useState<boolean>(false);
  const [expandedRowAction, setExpandedRowAction] = useState<number | null>(
    null
  );
  const [selectedRowButton, setSelectedRowButton] = useState<any>(false);
  const [getRiskLoading, setGetRiskLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const formRef = React.useRef<ProFormInstance>();
  const ContainerHeight = window.innerHeight - 40;
  const {
    totalRunningRisk,
    getLoading,
    setCurrentAction,
    runningRisks,
    selectedRisk,
    setSelectedRisk,
    setRunningRisks,
    setRunningRisksFilter,
    setTotalRunningRisk,
    setGetLoading,
    runningRisksFilter,
  } = useContext(SharedContext);
  const { filterRunningRisks } =
    useSharedContextFunctions() as SharedContextFunctions;

  const getUniqueKeysWithTypes = (array: any) => {
    const uniqueKeys = new Map();

    array.forEach((item: any) => {
      if (item.context) {
        Object.entries(item.context).forEach(([key, value]) => {
          if (!uniqueKeys.has(key)) {
            let type = DATA_NEEDED_TYPE.STRING; // Default type

            // Check the type of the value
            const valueStr = String(value);

            if (!isNaN(Number(valueStr)) && valueStr !== "") {
              type = DATA_NEEDED_TYPE.NUMBER;
            } else if (isDate(valueStr)) {
              type = DATA_NEEDED_TYPE.DATE;
            }

            uniqueKeys.set(key, { name: key, type });
          }
        });
      }
    });

    return Array.from(uniqueKeys.values()); // Convert Map back to an array
  };
  const [filterArgs, setfilterArgs] = useState({});
  const columnsList = getUniqueKeysWithTypes(runningRisksFilter);
  const handleSort = (fieldKey: string, sortOrder: Sortingtypes) => {
    setSortingOrder(sortOrder);
    setSortingField("CONTEXT." + fieldKey);
    setCurrentPage(1);
    filterRunningRisks(filterparams, {
      sortOrder: sortOrder,
      fieldKey: fieldKey,
    });
  };

  const onScroll = (e: React.UIEvent<HTMLElement, UIEvent>) => {
    if (
      Math.abs(
        e.currentTarget.scrollHeight -
          e.currentTarget.scrollTop -
          ContainerHeight
      ) <= 1 &&
      runningRisks.length < totalRunningRisk
    ) {
      appendData(runningRisks, filterparams);
    }
  };
  useEffect(() => {}, [runningRisks]);
  return (
    <>
      {" "}
      <Spin spinning={false} size="large" tip="Loading...">
        {" "}
        <List
          header={
            <div className="header-content">
              <div className="header-title">
                <h4>
                  {menu !== RUNNING_MENU.RISKS_HISTORY
                    ? getTranslation("OPEN-_ACTIONS", "labels")
                    : getTranslation("RISK_HISTORY", "labels")}{" "}
                </h4>
              </div>
              <div className="header-buttons">
                <FilterButton
                  copilotName={copilotName}
                  menu={menu}
                  appendData={appendData}
                  isFirstLoad={isFirstLoad}
                  setFilterparams={setFilterparams}
                  setSortingOrder={setSortingOrder}
                  setSortingField={setSortingField}
                  setSortingMenuActive={setSortingMenuActive}
                  formRef={formRef}
                  setfilterArgs={setfilterArgs}
                />
                {/* reload button */}
                <Button
                  onClick={() => {
                    if (menu === RUNNING_MENU.RISKS_HISTORY) {
                      setIsSecondColumnOpen(false);
                      setExpandedRowAction(null);
                    }
                    setGetLoading(true);
                    setTimeout(() => {
                      setGetLoading(false);
                    }, 2000);
                    let runningList: any = [];

                    if (createKey(copilotName) === createKey("fulfillment")) {
                      runningList = Fulfillement_Risk;
                    }

                    if (
                      createKey(copilotName) === createKey("demand matching")
                    ) {
                      runningList = Demand_Matching_Risk;
                    }
                    if (
                      createKey(copilotName) === createKey("supply matching")
                    ) {
                      runningList = Supply_Matching_Risk;
                    }
                    if (
                      createKey(copilotName) ===
                      createKey("inbound freight management")
                    ) {
                      runningList = Inbound_Freight_Risk;
                    }

                    setRunningRisks(runningList);
                    setRunningRisksFilter(runningList);
                    setTotalRunningRisk(runningList.length);

                    if (runningList.length > 0) {
                      if (menu !== RUNNING_MENU.RISKS_HISTORY) {
                        setCurrentAction(runningList.at(0)?.current_action);
                      }

                      setSelectedRisk(runningList.at(0));
                    } else {
                      setCurrentAction(null);
                      setSelectedRisk(null);
                    }
                  }}
                  icon={
                    <Tooltip title={getTranslation("RELOAD", "labels")}>
                      <ReloadOutlined />
                    </Tooltip>
                  }
                  shape="default"
                />
                {/* sorting button */}
                <Button
                  icon={
                    <SortingMenu
                      key="sorting-menu"
                      fields={columnsList}
                      onSort={handleSort}
                      sortingMenuActive={sortingMenuActive}
                      setSortingMenuActive={setSortingMenuActive}
                    />
                  }
                  shape="default"
                />
                {/* total badge */}
                <Badge
                  count={totalRunningRisk}
                  style={{
                    backgroundColor: "#004c97",
                    color: "white",
                    right: 0,
                    marginInlineStart: 10,
                    marginBottom: 4,
                    top: 0,
                  }}
                ></Badge>
              </div>
            </div>
          }
          bordered
          style={{
            borderRadius: "0px",
            background: "#fff",
            flex: 1,
          }}
        >
          {/* risk list */}
          <VirtualList
            data={runningRisks}
            height={ContainerHeight}
            // itemHeight={20}
            itemKey="id"
            onScroll={onScroll}
          >
            {(item: RisksItem, index: any) => (
              <List.Item
                key={item.id}
                className={
                  item.id === selectedRisk?.id
                    ? "run-card-selected"
                    : "run-card"
                }
                onClick={() => {
                  if (menu === RUNNING_MENU?.OPEN_ACTIONS) {
                    setIsSecondColumnOpen(true);
                  }
                  if (
                    item.id !== selectedRisk?.id &&
                    menu !== RUNNING_MENU?.OPEN_ACTIONS
                  ) {
                    setExpandedRowAction(null);
                    setIsSecondColumnOpen(false);
                  }
                  if (item.id !== selectedRisk?.id) {
                    setAcceptRejectState(false);
                    if (menu !== RUNNING_MENU.RISKS_HISTORY) {
                      setCurrentAction(item?.current_action);
                    }

                    //setCurrentAction(null);
                    // getCurrentActionFormatUI(item?.id);
                    setSelectedRisk(item);
                  }
                  if (
                    !selectedRowButton &&
                    menu !== RUNNING_MENU.RISKS_HISTORY
                  ) {
                    setCurrentAction(item?.current_action);
                  }
                }}
              >
                <List.Item.Meta
                  title={
                    menu === RUNNING_MENU.RISKS_HISTORY ? (
                      <RunningHistoryVirtualListTitle
                        copilotName={copilotName}
                        menu={menu}
                        handleRowClick={handleRowClick}
                        expandedRowAction={expandedRowAction}
                        item={item}
                        setGetRiskLoading={setGetRiskLoading}
                        setExpandedRowAction={setExpandedRowAction}
                        index={index}
                      />
                    ) : (
                      <RunningOpenActionsVirtualListTitle
                        copilotName={copilotName}
                        menu={menu}
                        item={item}
                        setSelectedRowButton={setSelectedRowButton}
                        setAcceptRejectState={setAcceptRejectState}
                      />
                    )
                  }
                  //   risk details ( columns)
                  description={
                    menu === RUNNING_MENU.RISKS_HISTORY ? (
                      <RunningHistoryVirtualListDescription
                        menu={menu}
                        item={item}
                        index={index}
                        expandedRowAction={expandedRowAction}
                        getRiskLoading={getRiskLoading}
                        handleRowClick={handleRowClick}
                        currentActionId={currentActionId}
                        setExpandedRowAction={setExpandedRowAction}
                        setGetRiskLoading={setGetRiskLoading}
                      />
                    ) : (
                      <RunningOpenActionsVirtualListDescription item={item} />
                    )
                  }
                />
              </List.Item>
            )}
          </VirtualList>
        </List>
      </Spin>
    </>
  );
};
