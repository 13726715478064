/** @format */

import i18n from "../parameters/i18n";

const generateBreadCrumbs = () => ({
  "Operations Dashboard": "Operations Dashboard",
  Users: i18n.t("labels:BREAD_CRUMBS", {
    first: i18n.t("ADMINISTRATION"),
    second: i18n.t("USERS"),
    lng: i18n.language,
  }),
  "/menu/configurations/actions": i18n.t("labels:BREAD_CRUMBS", {
    first: i18n.t("CONFIGURATION"),
    second: i18n.t("ACTIONS"),
    lng: i18n.language,
  }),
  "/menu/configurations/executions": i18n.t("labels:BREAD_CRUMBS", {
    first: i18n.t("CONFIGURATION"),
    second: i18n.t("EXECUTIONS"),
    lng: i18n.language,
  }),
  "/menu/configurations/risks": i18n.t("labels:BREAD_CRUMBS", {
    first: i18n.t("CONFIGURATION"),
    second: i18n.t("EXOBRAIN"),
    lng: i18n.language,
  }),
  "/menu/configurations": i18n.t("labels:BREAD_CRUMBS", {
    first: i18n.t("CONFIGURATION"),
    second: i18n.t("EXOBRAIN"),
    lng: i18n.language,
  }),
  Executions: i18n.t("labels:BREAD_CRUMBS", {
    first: i18n.t("CONFIGURATION"),
    second: i18n.t("EXECUTIONS"),
    lng: i18n.language,
  }),
  Actions: i18n.t("labels:BREAD_CRUMBS", {
    first: i18n.t("CONFIGURATION"),
    second: i18n.t("ACTIONS"),
    lng: i18n.language,
  }),
  /* Dashboards */
  /** user */
  "/menu/users": i18n.t("labels:BREAD_CRUMBS", {
    first: i18n.t("CONFIGURATION"),
    second: i18n.t("USERS"),
    lng: i18n.language,
  }),
  "/menu/users/config": i18n.t("labels:BREAD_CRUMBS", {
    first: i18n.t("CONFIGURATION"),
    second: i18n.t("USERS"),
    lng: i18n.language,
  }),
  /** logistic_events */
  "/menu/logistic_events": i18n.t("labels:BREAD_CRUMBS", {
    first: i18n.t("data:COPILOT_INBOUND_FREIGHT_MANAGEMENT"),
    second: i18n.t("OPEN_ACTIONS"),
    lng: i18n.language,
  }),
  /* Procurement */
  "/menu/procurement": i18n.t("labels:BREAD_CRUMBS", {
    first: i18n.t("PROCUREMENT"),
    second: i18n.t("OPEN_ACTIONS"),
    lng: i18n.language,
  }),
  "/menu/procurement/action-monitoring": i18n.t("labels:BREAD_CRUMBS", {
    first: i18n.t("PROCUREMENT"),
    second: i18n.t("ACTION_MONITORING"),
    lng: i18n.language,
  }),
  "/menu/procurement/action-analysis": i18n.t("labels:BREAD_CRUMBS", {
    first: i18n.t("PROCUREMENT"),
    second: i18n.t("ACTION_ANALYSIS"),
    lng: i18n.language,
  }),
  "/menu/procurement/action-roi": i18n.t("labels:BREAD_CRUMBS", {
    first: i18n.t("PROCUREMENT"),
    second: i18n.t("ACTION_ROI"),
    lng: i18n.language,
  }),
  /* Fulfillment */
  "/menu/fulfillement": i18n.t("labels:BREAD_CRUMBS", {
    first: i18n.t("FULFILLMENT"),
    second: i18n.t("OPEN_ACTIONS"),
    lng: i18n.language,
  }),
  "/menu/fulfillment/action-monitoring": i18n.t("labels:BREAD_CRUMBS", {
    first: i18n.t("FULFILLMENT"),
    second: i18n.t("ACTION_MONITORING"),
    lng: i18n.language,
  }),
  "/menu/fulfillment/action-analysis": i18n.t("labels:BREAD_CRUMBS", {
    first: i18n.t("FULFILLMENT"),
    second: i18n.t("ACTION_ANALYSIS"),
    lng: i18n.language,
  }),
  "/menu/fulfillment/action-roi": i18n.t("labels:BREAD_CRUMBS", {
    first: i18n.t("FULFILLMENT"),
    second: i18n.t("ACTION_ROI"),
    lng: i18n.language,
  }),

  /* Demand_matching */
  "/menu/demand_matching": i18n.t("labels:BREAD_CRUMBS", {
    first: i18n.t("DEMAND_MATCHING"),
    second: i18n.t("OPEN_ACTIONS"),
    lng: i18n.language,
  }),
  "/menu/demand_matching/action-roi": i18n.t("labels:BREAD_CRUMBS", {
    first: i18n.t("DEMAND_MATCHING"),
    second: i18n.t("ACTION_ROI"),
    lng: i18n.language,
  }),
  "/menu/demand_matching/action-monitoring": i18n.t("labels:BREAD_CRUMBS", {
    first: i18n.t("DEMAND_MATCHING"),
    second: i18n.t("ACTION_MONITORING"),
    lng: i18n.language,
  }),
  "/menu/demand_matching/action-analysis": i18n.t("labels:BREAD_CRUMBS", {
    first: i18n.t("DEMAND_MATCHING"),
    second: i18n.t("ACTION_ANALYSIS"),
    lng: i18n.language,
  }),

  /* Supply_matching */
  "/menu/supply_matching": i18n.t("labels:BREAD_CRUMBS", {
    first: i18n.t("SUPPLY_MATCHING"),
    second: i18n.t("OPEN_ACTIONS"),
    lng: i18n.language,
  }),
  "/menu/supply_matching/action-monitoring": i18n.t("labels:BREAD_CRUMBS", {
    first: i18n.t("SUPPLY_MATCHING"),
    second: i18n.t("ACTION_MONITORING"),
    lng: i18n.language,
  }),
  "/menu/supply_matching/action-analysis": i18n.t("labels:BREAD_CRUMBS", {
    first: i18n.t("SUPPLY_MATCHING"),
    second: i18n.t("ACTION_ANALYSIS"),
    lng: i18n.language,
  }),
  "/menu/supply_matching/action-roi": i18n.t("labels:BREAD_CRUMBS", {
    first: i18n.t("SUPPLY_MATCHING"),
    second: i18n.t("ACTION_ROI"),
    lng: i18n.language,
  }),
  "*": "NotFound ",
  /**codification */
  "/menu/codifications": i18n.t("labels:BREAD_CRUMBS", {
    first: i18n.t("ADMINISTRATION"),
    second: i18n.t("CODIFICATION"),
    lng: i18n.language,
  }),
  /**predefined Execution */
  "/menu/predefined_executions": i18n.t("labels:BREAD_CRUMBS", {
    first: i18n.t("ADMINISTRATION"),
    second: i18n.t("EXECUTIONS_PREDEFINED"),
    lng: i18n.language,
  }),
  /**Predefined Actions */
  "/menu/predefined_actions": i18n.t("labels:BREAD_CRUMBS", {
    first: i18n.t("ADMINISTRATION"),
    second: i18n.t("ACTIONS_PREDEFINED"),
    lng: i18n.language,
  }),
  /**Predefined Risks */
  "/menu/predefined_risks": i18n.t("labels:BREAD_CRUMBS", {
    first: i18n.t("ADMINISTRATION"),
    second: i18n.t("RISKS_PREDEFINED"),
    lng: i18n.language,
  }),
  /**organizations */
  "/menu/organization": i18n.t("labels:BREAD_CRUMBS", {
    first: i18n.t("ADMINISTRATION"),
    second: i18n.t("ORGANIZATIONS"),
    lng: i18n.language,
  }),
  /**licenses */
  "/menu/licenses": i18n.t("labels:BREAD_CRUMBS", {
    first: i18n.t("ADMINISTRATION"),
    second: i18n.t("Licenses"),
    lng: i18n.language,
  }),
  /**connections */
  "/menu/configurations/connections": i18n.t("labels:BREAD_CRUMBS", {
    first: i18n.t("CONFIGURATION"),
    second: i18n.t("Connections"),
    lng: i18n.language,
  }),
});

export const pageBreadCrumbs = generateBreadCrumbs();

i18n.on("languageChanged", () => {
  Object.assign(pageBreadCrumbs, generateBreadCrumbs());
});

export default pageBreadCrumbs;
