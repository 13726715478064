import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormInstance, message, notification } from "antd";
import { MainContext } from "../../../../utils/context";
import { WORK_MODE } from "../../../../constants/workModes";
import { constructActionParametersObject } from "../../../../utils/functions";
import { PREDEFINED_TYPES } from "../../../../constants/predefinedTypes";
import { PreDefinedActionItem } from "../../../../interfaces/pre-defined-action";
import { RootState } from "../../../../redux/store/store";
import { SharedContext } from "../context/sharedContext";
import { useTranslation } from "react-i18next";
import { createKey, getTranslation } from "../../../../utils/transaltion";
import {
  IDataNeeded,
  IOptionDataNeeded,
} from "../../../../interfaces/dataNeededURL";
import {
  formatDataNeededFromURLToList,
  isFormatPolicyDataValid,
  isFormatSettingsValid,
} from "../../../../utils/datatypesFunctions";
import Api from "../../../../services/main/api";
import {
  addPredefinedActionGlobal,
  setPredefinedActionsGlobal,
  updatePreDefinedActionGlobal,
} from "../../../../redux/reducers/predefinedActionsGlobal.Reducer";
import {
  addPredefinedExecutionGlobal,
  setPredefinedExecutionGlobal,
  updatePreDefinedExecutionGlobal,
} from "../../../../redux/reducers/predefinedExecutionsGlobal.Reducer";
import {
  addPredefinedRiskGlobal,
  setPredefinedRisksGlobal,
  updatePreDefinedRiskGlobal,
} from "../../../../redux/reducers/predefinedRisksGlobal.Reducer";
import PredefinedRisksService from "../../../../services/predefined-risks.service";
import { Reason } from "../../../../interfaces/reasons";
import handleApiError from "../../../../services/functions/handle-api-errors/handleApiError";
import { REQUEST } from "../../../../services/functions/handle-api-errors/const";
import PredefinedActionsService from "../../../../services/predefined-actions.service";
import PredefinedExecutionsService from "../../../../services/predefined-executions.service";
import { TFunction } from "i18next";

export interface SharedContextFunctionsInterface {
  getConfigurationFromURL: (
    form: FormInstance<any>,
    url: string,
    setSyncIconClassName: React.Dispatch<React.SetStateAction<string>>
  ) => Promise<void>;
  getDeletedItemNames: (
    recordItems: any[],
    originalArray: any[],
    id: string
  ) => Promise<void>;
  handleUpdateRisk: (record: any) => Promise<void>;
  handleUpdateExecution: (record: any) => Promise<void>;
  handleUpdateAction: (record: any) => Promise<void>;
  getActionParameters: (mode: string, record: any) => Promise<void>;
  getEnums: (typecomponent: string) => Promise<void>;

  getList: (typecomponent: string) => Promise<void>;
  goToConfig: (
    typecomponent: string,
    record: any,
    mode: string
  ) => Promise<void>;
  goToTable: (typecomponent: string) => Promise<void>;
  onFinish: (
    typecomponent: string,
    form: FormInstance<any>,
    setLoadingSave: React.Dispatch<React.SetStateAction<boolean>>,
    id?: string
  ) => Promise<void>;
  Delete: (
    typecomponent: string,
    id: any,
    setConfirmDeleteLoading: React.Dispatch<React.SetStateAction<boolean>>
  ) => Promise<void>;
  handleFilterAction: (params: any) => Promise<void>;
  handleFilterExecution: (params: any) => Promise<void>;

  handleFilterRisk: (params: any) => Promise<void>;
}

export const useSharedContextFunctions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  /**confs shared context */
  const {
    actionColor,
    setActionColor,
    executionColor,
    setExecutionColor,
    isAuto,
    setIsAuto,
    isDisable,
    setIsDisable,
    workMode,
    List,
    setList,
    setTotal,
    setGetLoading,
    setDynamicFields,
    dynamicFields,
    setValueEnumRoles,
    setValueEnumPreExecutions,
    setValueEnumCopilot,
    setDynamicFieldsSettings,
    setValueEnumPreActions,
    dynamicFieldsSettings,
    formSettings,
    formDataNeeded,
    setDataNeededURL,
    dataNeededURL,
  } = useContext(SharedContext);
  /** roles from redux */
  const Roles = useSelector((state: RootState) => state.roleReducer);

  /** get predefined exe from redux */
  const predefinedExecutionsRedux = useSelector(
    (state: RootState) => state.predefinedExecutionGlobalReducer
  );
  /**reasons from Redux */
  const ReasonsExecutions: Reason[] = useSelector(
    (state: RootState) => state.reasonsExecutionsReducer
  );
  /** get predefined action from redux */
  const predefinedActionsRedux = useSelector(
    (state: RootState) => state.predefinedActionGlobalReducer
  );
  /** get predefined copilotfrom redux */
  const copilotRedux = useSelector((state: RootState) => state.copilotReducer);
  /** get predefined risks from redux */
  const predefinedriksRedux = useSelector(
    (state: RootState) => state.predefinedRiskGlobalReducer
  );

  /**global context */
  const globalContext = useContext(MainContext);
  if (!globalContext) {
    return <></>;
  }
  const api = new Api(globalContext.context, globalContext.setContext, "");

  const getDeletedItemNames = (
    recordItems: any[],
    originalArray: any[],
    id: string
  ) => {
    const recordRole = recordItems?.find((role) => role.id === id);

    if (recordRole) {
      return recordRole.name;
    }

    const originalRole = originalArray.find((role) => role.id === id);
    if (originalRole) {
      return originalRole.name;
    }

    return "";
  };

  const getList = async (typecomponent: string) => {
    if (typecomponent === PREDEFINED_TYPES.ACTION_PREDEFINED) {
      getListAction();
    }
    if (typecomponent === PREDEFINED_TYPES.EXECUTION_PREDEFINED) {
      getListExecution();
    }
    if (typecomponent === PREDEFINED_TYPES.RISK_PREDEFINED) {
      getListRisk();
    }
  };

  const goToConfig = async (
    typecomponent: string,
    record: any,
    mode: string
  ): Promise<void> => {
    if (typecomponent === PREDEFINED_TYPES.ACTION_PREDEFINED) {
      navigate("/menu/add_predefined_actions", {
        state: {
          record,
          mode,
        },
      });
    }
    if (typecomponent === PREDEFINED_TYPES.EXECUTION_PREDEFINED) {
      navigate("/menu/add_predefined_executions", {
        state: {
          record,
          mode,
        },
      });
    }
    if (typecomponent === PREDEFINED_TYPES.RISK_PREDEFINED) {
      navigate("/menu/add_predefined_risks", {
        state: {
          record,
          mode,
        },
      });
    }
  };

  const goToTable = async (typecomponent: string) => {
    if (typecomponent === PREDEFINED_TYPES.ACTION_PREDEFINED) {
      navigate("/menu/predefined_actions");
    }
    if (typecomponent === PREDEFINED_TYPES.EXECUTION_PREDEFINED) {
      navigate("/menu/predefined_executions");
    }
    if (typecomponent === PREDEFINED_TYPES.RISK_PREDEFINED) {
      navigate("/menu/predefined_risks");
    }
  };

  const onFinish = async (
    typecomponent: string,
    form: FormInstance<any>,
    setLoadingSave: React.Dispatch<React.SetStateAction<boolean>>,
    id?: string
  ) => {
    if (typecomponent === PREDEFINED_TYPES.ACTION_PREDEFINED) {
      onFinishAction(form, setLoadingSave, id);
    }
    if (typecomponent === PREDEFINED_TYPES.EXECUTION_PREDEFINED) {
      onFinishExecution(form, setLoadingSave, id);
    }
    if (typecomponent === PREDEFINED_TYPES.RISK_PREDEFINED) {
      onFinishRisk(form, setLoadingSave, id);
    }
  };

  const Delete = async (
    typecomponent: string,
    id: any,
    setConfirmDeleteLoading: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    if (typecomponent === PREDEFINED_TYPES.ACTION_PREDEFINED) {
      DeleteAction(id, setConfirmDeleteLoading);
    }
    if (typecomponent === PREDEFINED_TYPES.EXECUTION_PREDEFINED) {
      DeleteExecution(id, setConfirmDeleteLoading);
    }
    if (typecomponent === PREDEFINED_TYPES.RISK_PREDEFINED) {
      DeleteRisk(id, setConfirmDeleteLoading);
    }
  };

  const getEnums = async (typecomponent: string): Promise<void> => {
    if (typecomponent === PREDEFINED_TYPES.ACTION_PREDEFINED) {
      getEnumsAction();
    }
    if (typecomponent === PREDEFINED_TYPES.EXECUTION_PREDEFINED) {
      getEnumsExecution();
    }
    if (typecomponent === PREDEFINED_TYPES.RISK_PREDEFINED) {
      getEnumsRisk();
    }
  };

  /**Action */
  const predefinedActionServices = new PredefinedActionsService(
    globalContext.context,
    globalContext.setContext,
    ""
  );
  const getConfigurationFromURL = async (
    form: FormInstance<any>,
    url: string,
    setSyncIconClassName: React.Dispatch<React.SetStateAction<string>>
  ) => {
    let initialValues: any = {};
    if (url) {
      await predefinedActionServices
        .syncPredefinedActionFromURL({ url: url })
        .then((res) => {
          const isFormatValid = isFormatSettingsValid(
            res?.data?.actionSettings
          );
          // check if dataNeeded structure is valid
          setDataNeededURL({});
          const l = formatDataNeededFromURLToList(res?.data);
          // populate name and reasons code from URL
          initialValues = {
            name: res?.data?.name,
            reasons: res?.data?.reasons,
          };
          form.setFieldsValue(initialValues);
          //populate DataNeeded from url

          setDataNeededURL(res?.data?.dataNeeded);
          //setDynamicFields(dataNeededFromURL);
          setSyncIconClassName("");
          //populate actionSettings from URL

          const dynamicFieldsSettingsValues = isFormatValid
            ? Object.entries(res?.data?.actionSettings || {}).map(
                ([key, value], index) => {
                  const { defaultValue, name, type, choice } = value as {
                    defaultValue: string;
                    name: string;
                    type: string;
                    suffix: string;
                    choice: any;
                  };
                  return {
                    key: index + 1,
                    defaultValue,
                    name,
                    type:
                      typeof defaultValue === "number" ? "NUMBER" : "STRING",
                    suffix: key,
                    choice: choice ?? false,
                  };
                }
              )
            : [];
          if (
            workMode === WORK_MODE.UPDATE &&
            Object.keys(res?.data?.actionSettings).length === 0
          ) {
            setDynamicFieldsSettings([]);
          } else {
            setDynamicFieldsSettings(dynamicFieldsSettingsValues);
          }
        })
        .catch((err) => {
          console.log(err);
          form.resetFields(["url"]);

          message.error(
            t(`errors:INVALID`, {
              label: t("URL"),
            })
          );
          setSyncIconClassName("");
          console.log(err);
          handleApiError(err);
        });
    }
  };

  const getListAction = async () => {
    setGetLoading(true);
    await predefinedActionServices
      .getPredefinedActionGlobal()
      .then((res) => {
        setGetLoading(false);

        setList(res?.items);
        setTotal(res?.total);
      })
      .catch((err) => {
        setGetLoading(false);
        handleApiError(err, REQUEST.GET);
      });
    setGetLoading(false);
  };

  const contructDataNeeded = (values: any) => {
    const modifiedValues = { ...values };
    const dataNeeded: any = {};

    Object.keys(modifiedValues).forEach((key) => {
      if (key.startsWith("Data_Name")) {
        const index = key.slice(9);
        const dataName = modifiedValues[key];
        const dataTypeKey = `Data_Type${index}`;

        delete modifiedValues[key];

        if (modifiedValues.hasOwnProperty(dataTypeKey)) {
          const dataType = modifiedValues[dataTypeKey];
          dataNeeded[dataName] = dataType;
          delete modifiedValues[dataTypeKey];
        }
      }
    });
    const filteredDynamicFields = dynamicFields.filter(
      (field) => field.dataName !== undefined
    );

    const transformedValues: { [key: string]: string } =
      filteredDynamicFields.reduce((result, item) => {
        if (item.dataName) {
          result[item.dataName] = item.dataType || "";
        }
        return result;
      }, {} as { [key: string]: string });

    modifiedValues["dataNeeded"] = transformedValues;
    return modifiedValues;
  };
  const contructDataNeededPredefinedRisk = (values: any) => {
    const modifiedValues = { ...values };
    const dataNeeded: any = {};

    Object.keys(modifiedValues).forEach((key) => {
      if (key.startsWith("Data_Name")) {
        const index = key.slice(9);
        const dataName = modifiedValues[key];
        const dataTypeKey = `Data_Type${index}`;
        const dataPositionKey = `Data_Position${index}`;
        // Create an object for the dataName if it doesn't exist
        if (!dataNeeded.hasOwnProperty(dataName)) {
          dataNeeded[dataName] = {};
        }

        // Check for dataType and add to the dataNeeded object
        if (modifiedValues.hasOwnProperty(dataTypeKey)) {
          const dataType = modifiedValues[dataTypeKey];
          dataNeeded[dataName]["TYPE"] = dataType;
        }
        // Check for dataPosition and add to the dataNeeded object
        if (modifiedValues.hasOwnProperty(dataPositionKey)) {
          const dataPosition = modifiedValues[dataPositionKey];
          dataNeeded[dataName]["POSITION"] = dataPosition;
        }
      }
    });

    return { dataNeeded: dataNeeded };
  };

  const onFinishAction = async (
    form: FormInstance<any>,
    setLoadingSave: React.Dispatch<React.SetStateAction<boolean>>,
    id?: string
  ) => {
    setGetLoading(true);
    formSettings
      .validateFields()
      .then(async () => {
        form
          .validateFields()
          .then(async (values) => {
            values["enabled"] = isDisable;
            values["canAuto"] = isAuto;
            values["uiConfiguration"] = { couleur: actionColor };
            let argsDataNeeded = { ...values };
            argsDataNeeded["dataNeeded"] = dataNeededURL;
            const argsActionSetting: any = constructActionParametersObject(
              dynamicFieldsSettings
            );
            argsDataNeeded["actionParameters"] = argsActionSetting?.parameters;
            if (workMode === WORK_MODE.CREATE) {
              argsDataNeeded["name"] = createKey(argsDataNeeded?.name);

              predefinedActionServices
                .createPredefinedAction(argsDataNeeded)
                .then((res) => {
                  form.resetFields();
                  formSettings.resetFields();
                  setDataNeededURL({});
                  setDynamicFieldsSettings([]);
                  setDynamicFields([]);
                  notification.success({
                    message: t(`errors:CREATE_SUCCESS`),
                    placement: "bottomRight",
                  });

                  dispatch(addPredefinedActionGlobal(res?.data));
                  setGetLoading(false);
                  navigate("/menu/predefined_actions");
                })
                .catch((err) => {
                  console.log(err);
                  setGetLoading(false);
                  if (err?.code === "ERR_NETWORK") {
                    notification.error({
                      message: t("errors:NETWORK_ERROR"),
                      placement: "bottomRight",
                    });
                  } else {
                    message.error(t("errors:CREATE_FAILED"));
                  }
                });
            } else {
              // here update
              const ID = id || "";
              argsDataNeeded["name"] = createKey(argsDataNeeded?.name);
              predefinedActionServices
                .updatePredefinedAction(ID, argsDataNeeded)
                .then((res: any) => {
                  form.resetFields();
                  setDataNeededURL({});
                  setDynamicFields([]);
                  notification.success({
                    message: t("errors:UPDATE_SUCCESS"),
                    placement: "bottomRight",
                  });
                  dispatch(updatePreDefinedActionGlobal(res?.data?.data));
                  setGetLoading(false);
                  goToTable(PREDEFINED_TYPES.ACTION_PREDEFINED);
                })
                .catch((err) => {
                  setGetLoading(false);
                  if (err?.code === "ERR_NETWORK") {
                    notification.error({
                      message: t("errors:NETWORK_ERROR"),
                      placement: "bottomRight",
                    });
                  } else {
                    notification.error({
                      message: t("errors:UPDATE_FAILED"),
                      placement: "bottomRight",
                    });
                  }
                });
            }
          })
          .catch((err) => {
            setGetLoading(false);
            if (err?.code === "ERR_NETWORK") {
              notification.error({
                message: t("errors:NETWORK_ERROR"),
                placement: "bottomRight",
              });
            } else {
              message.error(t("errors:CREATE_FAILED"));
            }
          });
      })
      .catch((err) => {
        setGetLoading(false);
        if (err?.code === "ERR_NETWORK") {
          notification.error({
            message: t("errors:NETWORK_ERROR"),
            placement: "bottomRight",
          });
        } else {
          message.error(t("errors:CREATE_FAILED"));
        }
      });
  };

  const DeleteAction = async (
    id: any,
    setConfirmDeleteLoading: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setConfirmDeleteLoading(true);
    await predefinedActionServices
      .deletePredefinedAction(id)
      .then((res) => {
        const updatedPredefinedActionList: Array<any> = List.filter(
          (PredefinedAction: PreDefinedActionItem) => PredefinedAction.id !== id
        );
        dispatch(setPredefinedActionsGlobal(updatedPredefinedActionList));
        getList(PREDEFINED_TYPES.ACTION_PREDEFINED);
        setConfirmDeleteLoading(false);
        notification.success({
          message: t("errors:DELETE_SUCCESS"),
          placement: "bottomRight",
        });
      })
      .catch((err) => {
        setConfirmDeleteLoading(false);
        handleApiError(err, REQUEST.DELETE);
      });
  };

  const handleUpdateAction = (record: any) => {
    if (workMode === WORK_MODE.UPDATE || workMode === WORK_MODE.VIEW) {
      setIsAuto(record?.canAuto);
      setIsDisable(record?.enabled);
      setActionColor(record?.uiConfiguration?.couleur || "#DBE7F1");
      setDataNeededURL(record?.dataNeeded || {});

      //updatelist dynamic fields for settings
      const dynamicFieldsSettingsValues = Object.keys(
        record?.actionParameters
      ).map((key, index) => ({
        key: index + 1,
        defaultValue: record?.actionParameters[key]?.defaultValue,
        name: record?.actionParameters[key]?.name,
        type: record?.actionParameters[key]?.type,
        suffix: key,
        choice: record?.actionParameters[key]?.choice,
      }));
      if (
        workMode === WORK_MODE.UPDATE &&
        Object.keys(record?.actionParameters).length === 0
      ) {
        setDynamicFieldsSettings([]);
      } else {
        setDynamicFieldsSettings(dynamicFieldsSettingsValues);
      }
    }
  };

  const getEnumsAction = async (): Promise<void> => {
    const updatedEnumRoles: { [key: string]: { text: string } } = {};
    Roles?.forEach((curr: any) => {
      updatedEnumRoles[curr.id] = {
        text: getTranslation(curr.name, "data"),
      };
    });

    setValueEnumRoles(updatedEnumRoles);

    const updatedEnumPreExecution: { [key: string]: { text: string } } = {};
    predefinedExecutionsRedux?.forEach((curr: any) => {
      updatedEnumPreExecution[curr.id] = {
        text: getTranslation(curr.name, "data"),
      };
    });

    setValueEnumPreExecutions(updatedEnumPreExecution);
  };
  const handleFilterAction = (params: any) => {
    let { name, role, execution, enabled } = params;

    // trim all  spaces at the beginning and end
    params = Object.fromEntries(
      Object.entries(params).map(([key, value]) => [
        key,
        typeof value === "string" ? value.replace(/^\s+|\s+$/g, "") : value,
      ])
    );

    if (!name && !role && !execution && !enabled) {
      getList(PREDEFINED_TYPES.ACTION_PREDEFINED);
    } else {
      let predefinedActionListFiltered = predefinedActionsRedux
        ?.filter((item: any) => {
          return (
            (!name ||
              getTranslation(item?.name, "data")
                .toLowerCase()
                .includes(name.toLowerCase())) &&
            (!role || item?.roles?.some((r: any) => r.id === role)) &&
            (!execution ||
              item?.executions?.some((r: any) => r.id === execution)) &&
            (!enabled || item?.enabled.toString() === enabled)
          );
        })
        .map((filteredItem: any) => {
          return {
            ...filteredItem,
          };
        });
      setTotal(predefinedActionListFiltered.length);
      setList(predefinedActionListFiltered);
    }
  };
  /**Execution */

  const predefinedExecutionServices = new PredefinedExecutionsService(
    globalContext.context,
    globalContext.setContext,
    ""
  );

  const getListExecution = async () => {
    setGetLoading(true);
    await predefinedExecutionServices
      .getPredefinedExecutionGlobal()
      .then((res: any) => {
        setGetLoading(false);
        setList(res?.items);
        setTotal(res?.total);
      })
      .catch((err: any) => {
        setGetLoading(false);
        handleApiError(err);
      });
    setGetLoading(false);
  };

  const onFinishExecution = async (
    form: FormInstance<any>,
    setLoadingSave: React.Dispatch<React.SetStateAction<boolean>>,
    id?: string
  ) => {
    setGetLoading(true);
    formDataNeeded
      .validateFields()
      .then(async (DataNeededValues) => {
        form
          .validateFields()
          .then(async (values) => {
            values["enabled"] = isDisable;
            values["canAuto"] = isAuto;
            values["uiConfiguration"] = { color: executionColor };
            let mergedArguments = { ...DataNeededValues, ...values };
            const args = contructDataNeeded(mergedArguments);
            if (workMode === WORK_MODE.CREATE) {
              args["name"] = createKey(args?.name);
              predefinedExecutionServices
                .createPredefinedExecution(args)
                .then((res) => {
                  //form.resetFields();
                  const reasonsValues = form
                    .getFieldValue("reasons")
                    ?.filter((reason: any) =>
                      ReasonsExecutions.some(
                        (item) => item.id === reason && item.is_system
                      )
                    );
                  // Reset all fields
                  form.resetFields();
                  // Set the value back to the field you want to retain
                  form.setFieldsValue({ reasons: reasonsValues });
                  form.setFieldsValue({ uiConfiguration: "#DBE7F1" });
                  setDynamicFields([]);
                  getListExecution();
                  notification.success({
                    message: t(`errors:CREATE_SUCCESS`),
                    placement: "bottomRight",
                  });
                  dispatch(addPredefinedExecutionGlobal(res?.data));
                  setGetLoading(false);
                })
                .catch((err) => {
                  setGetLoading(false);
                  if (err?.code === "ERR_NETWORK") {
                    notification.error({
                      message: t("errors:NETWORK_ERROR"),
                      placement: "bottomRight",
                    });
                  } else {
                    message.error(t("errors:CREATE_FAILED"));
                  }
                });
            } else {
              const ID = id || "";
              args["name"] = createKey(args?.name);
              predefinedExecutionServices
                .updatePredefinedExecution(ID, args)
                .then((res: any) => {
                  notification.success({
                    message: t("errors:UPDATE_SUCCESS"),
                    placement: "bottomRight",
                  });
                  form.resetFields();
                  setDynamicFields([]);

                  dispatch(updatePreDefinedExecutionGlobal(res?.data?.data));
                  setGetLoading(false);
                  getListExecution();

                  goToTable(PREDEFINED_TYPES.EXECUTION_PREDEFINED);
                })
                .catch((err) => {
                  setGetLoading(false);
                  if (err?.code === "ERR_NETWORK") {
                    notification.error({
                      message: t("errors:NETWORK_ERROR"),
                      placement: "bottomRight",
                    });
                  } else {
                    notification.error({
                      message: t("errors:UPDATE_FAILED"),
                      placement: "bottomRight",
                    });
                  }
                });
            }
          })
          .catch((err) => {
            setGetLoading(false);
            if (err?.code === "ERR_NETWORK") {
              notification.error({
                message: t("errors:NETWORK_ERROR"),
                placement: "bottomRight",
              });
            } else {
              message.error(t("errors:CREATE_FAILED"));
            }
          });
      })
      .catch((err) => {
        setGetLoading(false);
        if (err?.code === "ERR_NETWORK") {
          notification.error({
            message: t("errors:NETWORK_ERROR"),
            placement: "bottomRight",
          });
        } else {
          if (err?.errorFields) {
            message.error(t("errors:REQUIRED_FIELDS"));
          } else {
            message.error(t("errors:CREATE_FAILED") + " " + err);
          }
        }
      });
  };
  const DeleteExecution = async (
    id: any,
    setConfirmDeleteLoading: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setConfirmDeleteLoading(true);
    await predefinedExecutionServices
      .deletePredefinedExecution(id)
      .then((res) => {
        const updatedPredefinedExecutionList: Array<any> = List.filter(
          (PredefinedExecution: any) => PredefinedExecution.id !== id
        );
        dispatch(setPredefinedExecutionGlobal(updatedPredefinedExecutionList));
        setConfirmDeleteLoading(false);
        notification.success({
          message: t("errors:DELETE_SUCCESS"),
          placement: "bottomRight",
        });
        getListExecution();
      })
      .catch((err) => {
        setConfirmDeleteLoading(false);
        handleApiError(err, REQUEST.DELETE);
      });
  };

  const handleUpdateExecution = (record: any) => {
    if (workMode === WORK_MODE.UPDATE || workMode === WORK_MODE.VIEW) {
      setIsAuto(record.canAuto);
      setIsDisable(record.enabled);
      setExecutionColor(record?.uiConfiguration?.color || "#DBE7F1");
      const newDataFields: any = Object.entries(record.dataNeeded).map(
        ([dataName, dataType], index) => ({
          key: index + 1,
          dataName,
          dataType,
        })
      );

      if (workMode === WORK_MODE.UPDATE && newDataFields.length === 0) {
        setDynamicFields([]);
      } else {
        setDynamicFields(newDataFields);
      }
    }
  };
  const getEnumsExecution = async (): Promise<void> => {
    const updatedEnumRoles: { [key: string]: { text: string } } = {};
    Roles?.forEach((curr: any) => {
      updatedEnumRoles[curr.id] = {
        text: getTranslation(curr.name, "data"),
      };
    });

    setValueEnumRoles(updatedEnumRoles);
  };

  const handleFilterExecution = (params: any) => {
    let { name, role, enabled } = params;
    // trim all  spaces at the beginning and end
    params = Object.fromEntries(
      Object.entries(params).map(([key, value]) => [
        key,
        typeof value === "string" ? value.replace(/^\s+|\s+$/g, "") : value,
      ])
    );

    if (!name && !role && !enabled) {
      getListExecution();
    } else {
      let predefinedExecutionsListFiltered = predefinedExecutionsRedux
        ?.filter((item: any) => {
          return (
            (!name ||
              getTranslation(item?.name, "data")
                .toLowerCase()
                .includes(name.toLowerCase())) &&
            (!role || item?.roles?.some((r: any) => r.id === role)) &&
            (!enabled || item?.enabled.toString() === enabled)
          );
        })
        .map((filteredItem: any) => {
          return {
            ...filteredItem,
          };
        });
      //
      setTotal(predefinedExecutionsListFiltered.length);
      setList(predefinedExecutionsListFiltered);
    }
  };

  /**Risk */

  const predefinedRiskServices = new PredefinedRisksService(
    globalContext.context,
    globalContext.setContext
  );

  const getListRisk = async () => {
    setGetLoading(true);
    await predefinedRiskServices
      .getPredefinedRiskGlobal()
      .then((res) => {
        setGetLoading(false);
        setList(res?.items);
        setTotal(res?.total);
      })
      .catch((err) => {
        setGetLoading(false);
        handleApiError(err);
      });
    setGetLoading(false);
  };

  const onFinishRisk = async (
    form: FormInstance<any>,
    setLoadingSave: React.Dispatch<React.SetStateAction<boolean>>,
    id?: string
  ) => {
    setGetLoading(true);
    formDataNeeded
      .validateFields()
      .then(async (valuesDataNeeded: any) => {
        form
          .validateFields()
          .then(async (values) => {
            values["enabled"] = isDisable;
            const args = contructDataNeededPredefinedRisk(valuesDataNeeded);
            let args_ = JSON.parse(JSON.stringify(args));
            delete args_["dataNeeded"];
            args_["policyData"] = { ...args["dataNeeded"] };
            const isPolicyDataFormatValid = isFormatPolicyDataValid({
              ...args["dataNeeded"],
            });
            let mergedArguments: any = {};
            if (isPolicyDataFormatValid) {
              mergedArguments = { ...args_, ...values };
            } else {
              mergedArguments = { ...values };
            }

            if (workMode === WORK_MODE.CREATE) {
              mergedArguments["name"] = createKey(mergedArguments?.name);
              predefinedRiskServices
                .createResource(mergedArguments)
                .then(async (res) => {
                  form.resetFields();
                  setDynamicFields([]);
                  notification.success({
                    message: t(`errors:CREATE_SUCCESS`),
                    placement: "bottomRight",
                  });
                  await predefinedRiskServices
                    .getResource(res?.data?.id || "", ["copilot", "actions"])
                    .then((res) => {
                      dispatch(addPredefinedRiskGlobal(res));
                    });

                  setGetLoading(false);
                  navigate("/menu/predefined_risks");
                })
                .catch((err) => {
                  console.log(err);
                  setGetLoading(false);
                  if (err?.code === "ERR_NETWORK") {
                    notification.error({
                      message: t("errors:NETWORK_ERROR"),
                      placement: "bottomRight",
                    });
                  } else {
                    message.error(t("errors:CREATE_FAILED"));
                  }
                });
            } else {
              const ID = id || "";
              //  let mergedArguments = JSON.parse(JSON.stringify(args));
              delete mergedArguments["dataNeeded"];
              mergedArguments["policyData"] = args["dataNeeded"];
              mergedArguments["name"] = createKey(mergedArguments?.name);
              predefinedRiskServices
                .updateResource(ID, mergedArguments)
                .then((res: any) => {
                  form.resetFields();
                  notification.success({
                    message: t("errors:UPDATE_SUCCESS"),
                    placement: "bottomRight",
                  });
                  dispatch(updatePreDefinedRiskGlobal(res?.data?.data));
                  setGetLoading(false);
                  goToTable(PREDEFINED_TYPES.RISK_PREDEFINED);
                })
                .catch((err) => {
                  console.log(err);
                  setLoadingSave(false);
                  if (err?.code === "ERR_NETWORK") {
                    notification.error({
                      message: t("errors:NETWORK_ERROR"),
                      placement: "bottomRight",
                    });
                  } else {
                    notification.error({
                      message: t("errors:UPDATE_FAILED"),
                      placement: "bottomRight",
                    });
                  }
                });
            }
          })
          .catch((err) => {
            console.log(err);
            setGetLoading(false);
            if (err?.code === "ERR_NETWORK") {
              notification.error({
                message: t("errors:NETWORK_ERROR"),
                placement: "bottomRight",
              });
            } else {
              if (err?.errorFields) {
                message.error(t("errors:REQUIRED_FIELDS"));
              } else {
                message.error(t("errors:CREATE_FAILED") + " " + err);
              }
            }
          });
      })
      .catch((err) => {
        console.log(err);
        setGetLoading(false);
        if (err?.code === "ERR_NETWORK") {
          notification.error({
            message: t("errors:NETWORK_ERROR"),
            placement: "bottomRight",
          });
        } else {
          message.error(t("errors:CREATE_FAILED"));
        }
      });
  };

  const DeleteRisk = async (
    id: any,
    setConfirmDeleteLoading: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setConfirmDeleteLoading(true);
    await predefinedRiskServices
      .deleteResource(id)
      .then((res) => {
        const updatedPredefinedRiskList: Array<any> = List?.filter(
          (PredefinedRisk: any) => PredefinedRisk.id !== id
        );
        dispatch(setPredefinedRisksGlobal(updatedPredefinedRiskList));
        getListRisk();
        setConfirmDeleteLoading(false);
        notification.success({
          message: t("errors:DELETE_SUCCESS"),
          placement: "bottomRight",
        });
      })
      .catch((err) => {
        setConfirmDeleteLoading(false);
        handleApiError(err, REQUEST.DELETE);
      });
  };

  const handleUpdateRisk = (record: any) => {
    if (workMode === WORK_MODE.UPDATE || workMode === WORK_MODE.VIEW) {
      const isPolicyDataFormatValid = isFormatPolicyDataValid(
        record?.policyData
      );

      setIsDisable(record.enabled);
      let newDataFields: any = [];
      if (isPolicyDataFormatValid) {
        newDataFields = Object.entries(record.policyData).map(
          ([dataName, dataProps]: [string, any], index) => {
            const position = dataProps?.POSITION
              ? parseInt(dataProps?.POSITION, 10)
              : undefined;
            const dataType = dataProps?.TYPE;

            // Create a new object without the 'position' attribute if it doesn't exist
            const newDataField: {
              key: number;
              dataName: string;
              dataType: any;
              position?: number;
            } = {
              key: index + 1,
              dataName,
              dataType,
            };

            // Add 'position' attribute if it exists in dataProps
            if (position !== undefined) {
              newDataField.position = position;
            }

            return newDataField;
          }
        );
      }

      if (workMode === WORK_MODE.UPDATE && newDataFields.length === 0) {
        setDynamicFields([]);
      } else {
        setDynamicFields(newDataFields);
      }
    }
  };

  const getEnumsRisk = async (): Promise<void> => {
    const updatedEnumCopliot: { [key: string]: { text: string } } = {};
    copilotRedux?.forEach((curr: any) => {
      updatedEnumCopliot[curr.id] = {
        text: getTranslation(curr.name, "data"),
      };
    });

    setValueEnumCopilot(updatedEnumCopliot);

    const updatedEnumPreActions: { [key: string]: { text: string } } = {};
    predefinedActionsRedux?.forEach((curr: any) => {
      updatedEnumPreActions[curr.id] = {
        text: getTranslation(curr.name, "data"),
      };
    });

    setValueEnumPreActions(updatedEnumPreActions);
  };

  const handleFilterRisk = (params: any) => {
    let { name, copilot, action, enabled } = params;

    // trim all  spaces at the beginning and end
    params = Object.fromEntries(
      Object.entries(params).map(([key, value]) => [
        key,
        typeof value === "string" ? value.replace(/^\s+|\s+$/g, "") : value,
      ])
    );

    if (!name && !copilot && !action && !enabled) {
      getListRisk();
    } else {
      let predefinedActionListFiltered = predefinedriksRedux
        ?.filter((item: any) => {
          return (
            (!name ||
              getTranslation(item?.name, "data")
                .toLowerCase()
                .includes(name.toLowerCase())) &&
            (!copilot || item?.copilot?.id?.includes(copilot)) &&
            (!action || item?.actions?.some((r: any) => r.id === action)) &&
            (!enabled || item?.enabled.toString() === enabled)
          );
        })
        .map((filteredItem: any) => {
          return {
            ...filteredItem,
          };
        });
      setTotal(predefinedActionListFiltered.length);
      setList(predefinedActionListFiltered);
    }
  };

  return {
    getConfigurationFromURL,
    handleUpdateRisk,
    handleUpdateExecution,
    handleFilterAction,
    handleFilterExecution,

    handleFilterRisk,
    getEnums,
    Delete,
    onFinish,
    getList,
    goToConfig,
    goToTable,
    handleUpdateAction,
    getDeletedItemNames,
  };
};
export const extractDataNeededFromURL = (
  dataNeeded: any,
  hide?: string[]
): IDataNeeded => {
  const result: IDataNeeded = {
    INPUT: [],
    OUTPUT: [],
  };

  const transformAttributes = (data: any, output: IOptionDataNeeded[]) => {
    for (const key in data) {
      const obj = data[key];
      const attribute: IOptionDataNeeded = {
        value: key,
        type: obj.TYPE || "UNKNOWN",
        is_taggable: obj.IS_TAGGABLE || false,
      };

      // If it's an array, process its attributes recursively
      if (obj.TYPE === "ARRAY" || obj.TYPE === "OBJECT") {
        const children: IOptionDataNeeded[] = [];
        for (const subKey in obj) {
          if (
            subKey !== "TYPE" &&
            subKey !== "IS_TAGGABLE" &&
            (!hide || !hide.includes(subKey))
          ) {
            children.push({
              value: subKey,
              type: obj[subKey].TYPE || "UNKNOWN",
              is_taggable: obj[subKey].IS_TAGGABLE || false,
            });
          }
        }
        attribute.children = children;
      }

      output.push(attribute);
    }
  };

  transformAttributes(dataNeeded.INPUTS, result.INPUT);
  transformAttributes(dataNeeded.OUTPUTS, result.OUTPUT);
  return result;
};

interface PredefinedValues {
  targetRoute: string;
  backToLabel: string;
  configLabel: string;
}

export const getPredefinedValues = (
  typeComponent: string,
  t: TFunction
): PredefinedValues => {
  const targetRoute =
    typeComponent === PREDEFINED_TYPES.ACTION_PREDEFINED
      ? "/menu/predefined_actions"
      : typeComponent === PREDEFINED_TYPES.RISK_PREDEFINED
      ? "/menu/predefined_risks"
      : "/menu/predefined_executions";

  const backToLabel =
    typeComponent === PREDEFINED_TYPES.ACTION_PREDEFINED
      ? t("PREDEFINED_ACTION_BACK_TO")
      : typeComponent === PREDEFINED_TYPES.EXECUTION_PREDEFINED
      ? t("PREDEFINED_EXECUTION_BACK_TO")
      : t("PREDEFINED_RISK_BACK_TO");

  const configLabel =
    typeComponent === PREDEFINED_TYPES.ACTION_PREDEFINED
      ? t("PREDEFINED_ACTION_CONFIGURATION")
      : typeComponent === PREDEFINED_TYPES.EXECUTION_PREDEFINED
      ? t("PREDEFINED_EXECUTION_CONFIGURATION")
      : t("PREDEFINED_RISK_CONFIGURATION");

  return {
    targetRoute,
    backToLabel,
    configLabel,
  };
};
