import CrudService from "./main/crud.service";
import { Context } from "../interfaces/context";
import { FilterRules } from "./interfaces/FilterRules";
import { getFilterRule } from "./functions/getFilterRule";
import { getManyUrlQuery } from "./functions/getUrlQuery";
import { RISKS_SCOPES } from "../constants/risksScopes";

const resource = { name: "running/risks", isPublic: false };

class RunningRisksService extends CrudService {
  constructor(
    context: Context,
    setContext: (c: Context) => void,
    path?: string
  ) {
    super(resource, context, setContext, path);
  }
  /** get current  */
  async getCurrent(
    params: any,
    filters?: FilterRules,
    fulls?: string[],
    riskScope?: string
  ): Promise<any> {
    const src =
      riskScope === RISKS_SCOPES.ADMIN_SCOPE
        ? `/${this.ressource}/admin`
        : riskScope === RISKS_SCOPES.ERRONEOUS_SCOPE
        ? `/${this.ressource}/erroneous`
        : `/${this.ressource}/current`;
    const url = getManyUrlQuery(src, params, fulls);
    const filter = getFilterRule(filters);

    const { data } = await this.post(url, filter);
    return data;
  }
  /** start risk  */
  async startRisk(startParams: any): Promise<any> {
    const { data } = await this.post(
      `/organizations/${this.context.orga.id}/running/risks/start?full=reason&full=actions&full=contexts&full=executions&full=scopes`,
      startParams
    );
    return data;
  }
  /** get risk by id  */
  async getRiskByID(
    id: string,
    fulls = [
      "actions",
      "contexts",
      "current_action",
      "executions",
      "current_execution",
    ]
  ): Promise<any> {
    const { data } = await this.get(
      `/organizations/${this?.context?.orga?.id}/running/risks/${id}?format=ui&full=actions&full=contexts&full=current_action&full=executions&full=current_execution`
    );
    return data;
  }

  /**accept action */
  async AcceptRunningAction(id: string, contextId: string): Promise<any> {
    console.log("here accept action");

    const data = await this.post(
      `/organizations/${this.context.orga.id}/running/actions/` +
        id +
        `/accept`,
      { context: contextId }
    );
    console.log(data);
    return data;
  }
  /**Rejectt  Action*/
  async RejectRunningAction(id: string, args: any): Promise<any> {
    console.log("here reject action");
    console.log("args reject action", args);
    const data = await this.post(
      `/organizations/${this.context.orga.id}/running/actions/` +
        id +
        `/reject`,
      args
    );
    console.log(data);

    return data;
  }

  /**Accept Execution */
  async AcceptRunningExecution(id: string, contextId: string): Promise<any> {
    console.log("here accept execution");

    const data = await this.post(
      `/organizations/${this.context.orga.id}/running/executions/` +
        id +
        `/accept`,
      { context: contextId }
    );
    console.log(data);

    return data;
  }

  /**Reject  Execution*/
  async RejectRunningExecution(id: string, args: any): Promise<any> {
    console.log("here reject execution");

    const data = await this.post(
      `/organizations/${this.context.orga.id}/running/executions/` +
        id +
        `/reject`,
      args
    );
    console.log(data);

    return data;
  }

  /**stop risk */
  async stopRunningRisk(running_risk_id: string, reason: string): Promise<any> {
    const url = `/${this.ressource}/${running_risk_id}/stop?reason=${reason}`;

    const { data } = await this.get(url);
    return data;
  }
}

export default RunningRisksService;
