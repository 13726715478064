import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";

// Create browser history for Application Insights azure service
const browserHistory = createBrowserHistory();

// Initialize React Plugin for Application Insights azure service
const reactPlugin = new ReactPlugin();

// Initialize Application Insights with configuration
const AppInsights = new ApplicationInsights({
  config: {
    // Set instrumentation key from environment variable
    instrumentationKey: process.env.REACT_APP_INSTRUMENTATION_KEY,
    // Add React Plugin to extensions (this plugin is allowing the  tracking of React-specific metrics and events)
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});

AppInsights.loadAppInsights();

export { reactPlugin, AppInsights };
