import { ActionType, ProColumns, ProTable } from "@ant-design/pro-components";
import { Button, message, Modal, Space, Spin, Tag } from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import { ConnectionInterface } from "../../../interfaces/connection";
import { MainContext } from "../../../utils/context";
import { useTranslation } from "react-i18next";
import MenuOptions from "../../common/menu/MenuOptions";
import { WORK_MODE } from "../../../constants/workModes";
import ConnectionsService from "../../../services/connections.service";
import handleApiError from "../../../services/functions/handle-api-errors/handleApiError";
import { REQUEST } from "../../../services/functions/handle-api-errors/const";
import { getTranslation } from "../../../utils/transaltion";
import { isPermission } from "../../../utils/permissionsFunctions";
import { PERMISSIONS_LIST } from "../../../constants/permissions";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store/store";
import { useNavigate } from "react-router-dom";
import CustomIcon from "./components/ConnectionIcon";
import { TAG_STATUS } from "../../../constants/tagStatus";

export default function ConnectionList() {
  const globalContext = useContext(MainContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  /** current user permissions from redux */
  const permissionsList = useSelector(
    (state: RootState) => state.permissionReducer
  );
  /* Protable */
  const actionRef = useRef<ActionType>();
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState<any>(0);
  const [getConnectionsLoading, setGetConnectionsLoading] = useState(false);
  const [connectionTypes, setConnectionTypes] = useState([]);

  const [modalWidth, setModalWidth] = useState(520); // default width
  const contentRef = useRef<HTMLDivElement>(null);

  /* Connection Form */
  const [openConnTypeModal, setOpenConnTypeModal] = useState(false);

  useEffect(() => {
    if (openConnTypeModal && contentRef.current) {
      const contentWidth = contentRef.current.scrollWidth;
      setModalWidth(contentWidth + 40); // Add some padding
    }
  }, [openConnTypeModal]);

  const goToConnectionConfiguration = async (
    mode: string,
    record?: any
  ): Promise<void> => {
    navigate("/menu/configurations/connections/details", {
      state: {
        record: record,
        workModeConnection: mode,
      },
    });
  };

  /* Services  */
  if (!globalContext) {
    return <></>;
  }
  const connectionsService = new ConnectionsService(
    globalContext.context,
    globalContext.setContext
  );
  /* Connections Service */
  const deleteConnection = async (id: string) => {
    await connectionsService
      .deleteResource(id)
      .then((res) => {
        message.success("Execution successfully deleted");
        filterListeConnections({ current: 1, pageSize: 20 });
      })
      .catch((err) => {
        handleApiError(err, REQUEST.DELETE);
      });
  };

  const filterListeConnections = async (params: any, filters?: any) => {
    setGetConnectionsLoading(true);
    // let filters_ = JSON.parse(JSON.stringify(filters));

    await connectionsService
      .getResources(params)
      .then((response: any) => {
        setGetConnectionsLoading(false);
        setRows(response?.items);
        setTotal(response?.total);
      })
      .catch((err: any) => {
        setGetConnectionsLoading(false);
        handleApiError(err);
      });
  };

  const getConnectionsTypes = async () => {
    await connectionsService
      .connectionsTypes()
      .then((response: any) => {
        setConnectionTypes(response?.items);
      })
      .catch((err: any) => {
        handleApiError(err);
      });
  };

  const columns: ProColumns<ConnectionInterface>[] = [
    {
      title: t("TYPE"),
      dataIndex: "type",
      valueType: "text",
      render: (text, record) => getTranslation(record?.type || "", "data"),
    },
    {
      title: getTranslation("NAME", "data"),
      dataIndex: "name",
      valueType: "text",
    },
    {
      title: getTranslation("CONNECTED", "data"),
      dataIndex: "Connected",
      valueType: "checkbox",
      render: (text, record) => (
        <span>
          <Tag color={record.connected ? TAG_STATUS.SUCCESS : TAG_STATUS.ERROR}>
            {record.connected
              ? getTranslation("CONNECTED", "data")
              : getTranslation("NOT CONNECTED", "data")}
          </Tag>
        </span>
      ),
    },
    {
      title: t("MORE_ACTIONS"),
      valueType: "option",
      key: "option",
      width: "20%",
      align: "center",
      render: (text, record) => (
        <span>
          <MenuOptions
            viewRecord={() => {
              goToConnectionConfiguration(WORK_MODE.VIEW, record);
            }}
            editRecord={() => {
              goToConnectionConfiguration(WORK_MODE.UPDATE, record);
            }}
            deleteRecord={() => {
              deleteConnection(record.id);
            }}
            title={t("errors:CONFIRM_DELETE_MESSAGE")}
          />
        </span>
      ),
    },
  ];

  return (
    <>
      <Spin spinning={getConnectionsLoading} size="large" tip="Loading...">
        <ProTable<ConnectionInterface>
          dataSource={rows}
          columns={columns}
          actionRef={actionRef}
          cardBordered
          toolBarRender={() => [
            isPermission(permissionsList, PERMISSIONS_LIST.ORG_ADMIN) && (
              <Button
                type="primary"
                className="button-add-risk"
                icon={<PlusOutlined />}
                onClick={() => {
                  getConnectionsTypes();
                  setOpenConnTypeModal(true);
                  //goToConnectionConfiguration(WORK_MODE.CREATE);
                }}
              >
                {t("ADD_BUTTON_TEXT")}
                {t("Connection")}
              </Button>
            ),
          ]}
          editable={{
            type: "multiple",
          }}
          columnsState={{
            persistenceKey: "pro-table-singe-demos",
            persistenceType: "localStorage",
          }}
          rowKey="id"
          search={
            // deactivated temporary
            false && {
              span: { xs: 24, sm: 12, md: 8, lg: 8, xl: 8, xxl: 8 },
              labelWidth: "auto",
              searchText: t("SEARCH_TEXT_BUTTON"),
              resetText: t("RESET_TEXT_BUTTON"),
              className: "custom-search-form",
            }
          }
          options={{
            setting: {
              listsHeight: 400,
            },
          }}
          form={{
            syncToUrl: (values, type) => {
              if (type === "get") {
                return {
                  ...values,
                  created_at: [values.startTime, values.endTime],
                };
              }
              return values;
            },
          }}
          pagination={{
            pageSize: 20,
            total: total,
            onChange(page, pageSize) {},
          }}
          request={(params = {}, sort, filter): any => {
            const { name, action, model, current, pageSize } = params;
            filterListeConnections(
              {
                current,
                pageSize,
              },
              {
                name: { value: name, operator: "HAS" },
              }
            );

            return;
          }}
          dateFormatter="string"
          headerTitle={t("Connections")}
        />
      </Spin>

      <Modal
        destroyOnClose={true}
        title={t("Select type")}
        open={openConnTypeModal}
        onCancel={() => {
          setOpenConnTypeModal(false);
        }}
        style={{ maxWidth: 800 }}
        width={modalWidth}
        footer={null}
      >
        {connectionTypes?.map((connection: string, index) => (
          <Space
            key={connection + index}
            onClick={() => {
              goToConnectionConfiguration(WORK_MODE.CREATE);
            }}
            style={{ whiteSpace: "nowrap" }}
            ref={contentRef}
          >
            <CustomIcon
              name={connection}
              title={getTranslation(connection, "data")}
            />
          </Space>
        ))}
      </Modal>
    </>
  );
}
