import { notification } from "antd";
import { Dispatch, SetStateAction, useContext } from "react";
import { SharedContext } from "../context/SharedContext";
import { useSelector } from "react-redux";
import { MainContext } from "../../../../utils/context";
import { RootState } from "../../../../redux/store/store";
import { Reason } from "../../../../interfaces/reasons";
import { getCopilotId } from "../../../../utils/functions";
import { createKey } from "../../../../utils/transaltion";
import { Role } from "../../../../interfaces/role";
import { RUNNING_MENU } from "../../../../constants/riskMenus";
import { PAGES_SIZE } from "../../../../constants/pagination";
import { getOtherOption } from "./runningMapping.logic";
import RunningRisksService from "../../../../services/running-risks.service";
import ExecutionsService from "../../../../services/execution-confs.service";
import ActionsService from "../../../../services/action-confs.service";
import handleApiError from "../../../../services/functions/handle-api-errors/handleApiError";
import { STATUS } from "../../../../constants/status";
/** interface custom hook */
export interface SharedContextFunctions {
  getCurrentRiskActions: (
    setOtherOptions: React.Dispatch<any>,
    setLoadingOtherOptions: React.Dispatch<boolean>
  ) => void;
  getCurrentActionFormatUI: (id: any) => void;
  stopRisk: (
    id: string,
    reason: string,
    typeCopilot: string,
    menu: string,
    setLoadingStop: Dispatch<SetStateAction<any>>
  ) => void;
  extractPositionAttributes: (id: string) => {
    name: string;
    type: string;
  }[];
  getEnums: (
    setValueEnumPredefinedAction: React.Dispatch<React.SetStateAction<any>>,
    setValueEnumPredefinedExecutions: React.Dispatch<React.SetStateAction<any>>
  ) => void;
  handleOnSelectReasonCode: (
    value: string,
    currentActionExecutionReasons: any[],
    setInputOtherVisible: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  reasonstoLowerCase: (reasons: any) => void;
  getAccessAcceptReject: (roles: any[], rolesContext: Role[]) => Boolean;
  filterRunningRisksHistory: (
    type: string,
    params: any,
    filters: any,
    fulls: any,
    runningRisk: any[]
  ) => Promise<void>;
  filterRunningOpenActions: (
    type: string,
    params: any,
    filters: any,
    fulls: any
  ) => Promise<void>;
  filterRunningOpenActionsAppend: (
    type: string,
    params: any,
    filters: any,
    fulls: any,
    runningRisk: any[]
  ) => Promise<void>;
  getRunningRiskById: (riskId: string) => Promise<void>;
  rejectActionOrExecution: (
    copilotName: string,
    RiskId: string,
    menu: string,
    id: string,
    args: any,
    typeReject: string,
    setLoadingReject: React.Dispatch<React.SetStateAction<boolean>>,

    setAcceptRejectState?: Dispatch<SetStateAction<boolean>>
  ) => void;
  showNotificationAccept: () => void;
  acceptActionOrExecution: (
    copilotName: string,
    RiskId: string,
    menu: string,
    id: string,
    contextId: string,
    type: string,
    setLoadingAccept: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  handleReasonCodeModal: (
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  showDrawer: (
    setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  closeAddReasonCodeModal: (
    setOpenAddReasonCodeModal: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  onClose: (
    setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  filterCurrentActionreasons: (
    type: string,
    reasons: Reason[],
    currentAct: any
  ) => void;
}
export const useSharedContextFunctions = () => {
  const globalContext = useContext(MainContext);
  /** get copilots from redux */
  const copilotList = useSelector(
    (state: RootState) => state.userCopilotReducer
  );
  const reasonsActions: Reason[] = useSelector(
    (state: RootState) => state.reasonsActionsReducer
  );
  /**reasonsexecutions from Redux */
  const reasonsExecutions: Reason[] = useSelector(
    (state: RootState) => state.reasonsExecutionsReducer
  );

  /** get predefined risks from redux */
  const predefinedRiskList = useSelector(
    (state: RootState) => state.predefinedRiskOrgaReducer
  );
  /** shared vars  */
  const {
    setOpenAddReasonCodeModal,
    setGetLoading,
    setTotalRunningRisk,
    setRunningRisks,
    runningRisks,
    setRunningRisksFilter,
    currentAction,
    setOpenDrawer,
    setCurrentAction,
    setCurrentActionExecutionReasons,
    actionConfs,
    setActionConfs,
    setCurrentRiskActions,
    setExecConfs,
    setSelectedRisk,
    riskScope,
  } = useContext(SharedContext);
  if (!globalContext) {
    return <></>;
  }

  const runningRisksService = new RunningRisksService(
    globalContext?.context,
    globalContext?.setContext,
    ""
  );
  const executionsServices = new ExecutionsService(
    globalContext.context,
    globalContext.setContext
  );
  const actionsServices = new ActionsService(
    globalContext.context,
    globalContext.setContext
  );

  const extractPositionAttributes = (
    copilotId: string
  ): {
    name: string;
    type: string;
  }[] => {
    // this function returns the 5 fields of the risk context that has a position property
    const positionAttributes: {
      name: string;
      type: string;
    }[] = [];
    let filteredPredefinedRiskList: any;
    // predefinedRiskList predefined risks from redux
    // Filter risks by copilot if a copilot ID is provided
    if (copilotId) {
      // if we have the copilot's id  then filter  the risk by copilot
      filteredPredefinedRiskList = predefinedRiskList?.filter(
        (obj: any) => obj.copilot.id === copilotId
      );
    } else {
      filteredPredefinedRiskList = predefinedRiskList;
    }
    // if  the same fields appear multiple time in different risks then only take one  occurrence
    // only take the fields that have the position property

    const uniqueNames: { [name: string]: boolean } = {};
    filteredPredefinedRiskList?.forEach((item: any) => {
      const { policyData } = item;
      const attributes = Object.keys(policyData);

      attributes?.forEach((attribute) => {
        const { POSITION, TYPE } = policyData[attribute];
        if (POSITION) {
          if (!uniqueNames[attribute]) {
            positionAttributes.push({ name: attribute, type: TYPE });
            uniqueNames[attribute] = true;
          }
        }
      });
    });
    return positionAttributes;
  };

  const getAccessAcceptReject = (
    roles: any[],
    rolesContext: Role[]
  ): boolean => {
    // Checking if any of the Ids from roles exist in rolesContext
    const hasCommonId = roles.some((roleId) =>
      rolesContext.some((role) => role.id === roleId.id)
    );
    return hasCommonId;
  };
  /** get running risks History  */

  const filterRunningRisksHistory = async (
    type: string,
    params: any,
    filters: any,
    fulls: any,
    runningRisk: any[]
  ): Promise<void> => {
    let idCopilot = "";
    setGetLoading(true);
    idCopilot = getCopilotId(copilotList, createKey(type))?.toString();
    idCopilot &&
      (await runningRisksService
        .filterResource(params, { ...filters, copilot: idCopilot }, fulls)
        .then(async (res: any) => {
          setRunningRisks(runningRisk.concat(res?.items));
          // setRunningRisks(res?.items);
          setRunningRisksFilter(res?.items);
          setTotalRunningRisk(res?.total);
        })
        .catch((err: any) => {
          handleApiError(err);
        }));
    setGetLoading(false);
  };
  /** get running risks OpenActions  */

  const filterRunningOpenActions = async (
    type: string,
    params: any,
    filters: any,
    fulls: any
  ): Promise<void> => {
    let idCopilot = "";
    setGetLoading(true);
    idCopilot = getCopilotId(copilotList, createKey(type))?.toString();

    idCopilot &&
      (await runningRisksService
        .getCurrent(
          params,
          { ...filters, copilot: idCopilot },
          fulls,
          riskScope
        )
        .then(async (res: any) => {
          setRunningRisks(res?.items);
          setRunningRisksFilter(res?.items);
          setTotalRunningRisk(res?.total);
        })
        .catch((err: any) => {
          handleApiError(err);
        }));
    setGetLoading(false);
  };

  const filterRunningOpenActionsAppend = async (
    type: string,
    params: any,
    filters: any,
    fulls: any,
    runningRisk: any[]
  ): Promise<void> => {
    let idCopilot = "";
    setGetLoading(true);
    idCopilot = getCopilotId(copilotList, createKey(type))?.toString();

    console.log("--------------------Append  filters", filters);

    console.log("----------Append  params", params);

    idCopilot &&
      (await runningRisksService
        .getCurrent(
          params,
          { ...filters, copilot: idCopilot },
          fulls,
          riskScope
        )
        .then(async (res: any) => {
          // console.log("----runningRisks", runningRisk);

          console.log("----filterRunningOpenActionsAppend", res);
          setRunningRisks(runningRisk.concat(res?.items));
          setRunningRisksFilter(res?.items);
          setTotalRunningRisk(res?.total);

          if (res.total > 0) {
            getCurrentActionFormatUI(res.items.at(0)?.id);
            setSelectedRisk(res.items.at(0));
          } else {
            console.log(" here current action = null");
            setCurrentAction(null);
            setSelectedRisk(null);
          }

          setSelectedRisk(res.items.at(0));
        })
        .catch((err: any) => {
          handleApiError(err);
        }));
    setGetLoading(false);
  };

  /**get current action format UI */
  const getCurrentActionFormatUI = async (id: any): Promise<void> => {
    const params = {
      current: 1,
      pageSize: PAGES_SIZE,
      format: "ui",
    };
    const fullOptions = [
      "current_action",
      "contexts",
      "executions",
      "current_execution",
    ]; // took out action conf and executon conf
    await runningRisksService
      .getCurrent(params, { id: id }, fullOptions, riskScope)
      .then(async (res: any) => {
        setCurrentAction(res?.items?.at(0)?.current_action);
      })
      .catch((err: any) => {
        handleApiError(err);
      });
  };

  /** getcurrent Risk's actions */
  const getCurrentRiskActions = async (
    setOtherOptions: React.Dispatch<any>,
    setLoadingOtherOptions: React.Dispatch<boolean>
  ): Promise<void> => {
    const params = {
      current: 1,
      pageSize: PAGES_SIZE,
    };
    let otherOptionTable: any = [];
    const fullOptions = ["actions", "contexts", "executions", "user"]; // took out action conf and executon conf
    await runningRisksService
      .filterResource(params, { id: currentAction?.risk?.id }, fullOptions)
      .then(async (res: any) => {
        setCurrentRiskActions(res?.items?.at(0));
        // console.log("result other option", res);
        otherOptionTable = getOtherOption(
          currentAction.id,
          res?.items?.at(0)?.actions,
          reasonsActions,
          reasonsExecutions
        );
        setOtherOptions(otherOptionTable);
        setLoadingOtherOptions(false);
      })
      .catch((err: any) => {
        setLoadingOtherOptions(false);
        handleApiError(err);
      });
  };

  const getRunningRiskById = async (riskId: string): Promise<void> => {
    const risk = await runningRisksService
      .getRiskByID(riskId)
      .then(async (res: any) => {
        return res;
      })
      .catch((err: any) => {
        handleApiError(err);
      });
    return risk;
  };
  /**Accept  */
  const acceptActionOrExecution = async (
    copilotName: string,
    RiskId: string,
    menu: string,
    id: string,
    contextId: string,
    type: string,

    setLoadingAccept: React.Dispatch<React.SetStateAction<boolean>>
  ): Promise<void> => {
    let idCopilot = "";

    idCopilot = getCopilotId(copilotList, createKey(copilotName))?.toString();

    if (type === "Action") {
      await runningRisksService
        .AcceptRunningAction(id, contextId)
        .then(async (res: any) => {
          setOpenDrawer(false);
          setLoadingAccept(false);
          if (menu === RUNNING_MENU.RISKS_HISTORY) {
            await runningRisksService
              .getRiskByID(RiskId)
              .then(async (res: any) => {
                console.log("************", res);
                const updatedCurrentAction = res?.actions?.find(
                  (action: any) => action.id === currentAction?.id
                );
                console.log(
                  "**accept action updated current action*********",
                  updatedCurrentAction
                );

                setCurrentAction(updatedCurrentAction);
                setSelectedRisk(res);
              })
              .catch((err: any) => {
                handleApiError(err);
              });
          } else {
            // filterRunningOpenActions(
            filterRunningOpenActionsAppend(
              copilotName,
              { current: 1, pageSize: PAGES_SIZE },
              {},
              ["current_action", "current_execution"],
              []
            );
            getCurrentActionFormatUI(RiskId);
          }

          notification.success({
            message: "Action successfully accepted",
            placement: "bottomRight",
          });
        })
        .catch((err: any) => {
          setLoadingAccept(false);
          if (err?.code === "ERR_NETWORK") {
            notification.error({
              message: "Network Error",
              placement: "bottomRight",
            });
          }
        });
    } else {
      // here it's type= execution
      await runningRisksService
        .AcceptRunningExecution(id, contextId)
        .then(async (res: any) => {
          setOpenDrawer(false);
          setLoadingAccept(false);
          if (menu === RUNNING_MENU.RISKS_HISTORY) {
            await runningRisksService
              .getRiskByID(RiskId)
              .then(async (res: any) => {
                const updatedCurrentAction = res?.actions?.find(
                  (action: any) => action.id === currentAction?.id
                );
                setCurrentAction(updatedCurrentAction);
                setSelectedRisk(res);
              })
              .catch((err: any) => {
                handleApiError(err);
              });
          } else {
            filterRunningOpenActions(
              copilotName,
              { current: 1, pageSize: PAGES_SIZE },
              {},
              ["current_action", "current_execution"]
            );
            getCurrentActionFormatUI(RiskId);
          }

          notification.success({
            message: "Execution successfully accepted",
            placement: "bottomRight",
          });
        })
        .catch((err: any) => {
          setLoadingAccept(false);
          if (err?.code === "ERR_NETWORK") {
            notification.error({
              message: "Network Error",
              placement: "bottomRight",
            });
          }
        });
    }
  };

  /**Stop Running risk */
  const stopRisk = async (
    id: string,
    reason: string,
    typeCopilot: string,
    menu: string,
    setLoadingStop: Dispatch<SetStateAction<any>>
  ): Promise<void> => {
    setLoadingStop(id);
    if (id) {
      await runningRisksService
        .stopRunningRisk(id, reason)
        .then(async (res: any) => {
          if (menu === RUNNING_MENU.RISKS_HISTORY) {
            setLoadingStop(null);
            filterRunningRisksHistory(
              typeCopilot,
              { current: 1, pageSize: PAGES_SIZE },
              {},
              ["model"],
              []
            );
          } else {
            setLoadingStop(null);

            filterRunningOpenActions(
              typeCopilot,
              { current: 1, pageSize: PAGES_SIZE },
              {},
              ["current_action", "current_execution"]
            );
          }

          notification.success({
            message: "Risk successfully stopped",
            placement: "bottomRight",
          });
        })
        .catch((err: any) => {
          setLoadingStop(null);
          handleApiError(err);
        });
    }
  };

  const filterCurrentActionreasons = (
    type: string,
    reasons: Reason[],
    confID: any
  ) => {
    if (type === "ACTION") {
      actionsServices.getResource(confID).then((res) => {
        setCurrentActionExecutionReasons(
          reasons.filter((reason) =>
            res?.data?.data?.reasons?.some(
              (actionReason: Reason) => actionReason.id === reason.id
            )
          )
        );
      });
    } else {
      executionsServices.getExecutionByTd(confID).then((res) => {
        setCurrentActionExecutionReasons(
          reasons.filter((reason) =>
            res?.data?.reasons?.some(
              (execReason: Reason) => execReason.id === reason.id
            )
          )
        );
      });
    }
  };
  //reject action
  const rejectActionOrExecution = async (
    copilotName: string,
    RiskId: string,
    menu: string,
    id: string,
    args: any,
    typeReject: string,
    setLoadingReject: React.Dispatch<React.SetStateAction<boolean>>,

    setAcceptRejectState?: Dispatch<SetStateAction<boolean>>
  ): Promise<void> => {
    if (typeReject === "Action") {
      await runningRisksService
        .RejectRunningAction(id, args)
        .then(async (res: any) => {
          setOpenDrawer(false);
          setLoadingReject(false);
          setAcceptRejectState && setAcceptRejectState(false);
          setGetLoading(true);
          setOpenAddReasonCodeModal(false);
          if (menu === RUNNING_MENU.RISKS_HISTORY) {
            await runningRisksService
              .getRiskByID(RiskId)
              .then(async (res: any) => {
                const updatedCurrentAction = res?.actions?.find(
                  (action: any) => action.id === currentAction?.id
                );
                setCurrentAction(updatedCurrentAction);
                setSelectedRisk(res);
              })
              .catch((err: any) => {
                handleApiError(err);
              });
          } else {
            // filterRunningOpenActions(
            filterRunningOpenActionsAppend(
              copilotName,
              { current: 1, pageSize: PAGES_SIZE },
              {},
              ["current_action", "current_execution"],
              []
            );
            getCurrentActionFormatUI(RiskId);
          }
        })
        .catch((err: any) => {
          setLoadingReject(false);

          if (err?.code === "ERR_NETWORK") {
            notification.error({
              message: "Network Error",
              placement: "bottomRight",
            });
          }
        });
      setGetLoading(false);
    } else {
      await runningRisksService
        .RejectRunningExecution(id, args)
        .then(async (res: any) => {
          setLoadingReject(false);

          setOpenDrawer(false);
          setAcceptRejectState && setAcceptRejectState(false);
          setGetLoading(true);
          if (menu === RUNNING_MENU.RISKS_HISTORY) {
            await runningRisksService
              .getRiskByID(RiskId)
              .then(async (res: any) => {
                const updatedCurrentAction = res?.actions?.find(
                  (action: any) => action.id === currentAction?.id
                );
                setCurrentAction(updatedCurrentAction);
                setSelectedRisk(res);
              })
              .catch((err: any) => {
                handleApiError(err);
              });
          } else {
            //  filterRunningOpenActions(
            filterRunningOpenActionsAppend(
              copilotName,
              { current: 1, pageSize: PAGES_SIZE },
              {},
              ["current_action", "current_execution"],
              []
            );
            getCurrentActionFormatUI(RiskId);
          }
          notification.success({
            message: "This execution has been rejected ",
            placement: "bottomRight",
          });
        })
        .catch((err: any) => {
          setLoadingReject(false);

          if (err?.code === "ERR_NETWORK") {
            notification.error({
              message: "Network Error",
              placement: "bottomRight",
            });
          }
        });
      setGetLoading(false);
    }
  };
  // show notification accept
  const showNotificationAccept = () => {
    notification.info({
      message: ``,
      description: "the Action has been accepted succesfully",
      placement: "bottomRight",
    });
  };
  //open modal reason code for procurement
  const handleReasonCodeModal = (
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
  ): void => {
    setOpenModal(true);
  };
  //to show the side bar
  const showDrawer = async (
    setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setOpenDrawer(true);
  };
  // close modal reasoncode
  const closeAddReasonCodeModal = (
    setOpenAddReasonCodeModal: React.Dispatch<React.SetStateAction<boolean>>
  ): void => {
    setOpenAddReasonCodeModal(false);
  };
  // to close thsetOpenDrawere side bar
  const onClose = (
    setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>
  ): void => {
    setOpenDrawer(false);
  };

  const reasonstoLowerCase = (reasons: any) => {
    return reasons?.map((item: any) => ({
      ...item,
      name: item?.name?.toLowerCase().replace(/_/g, " "),
    }));
  };
  const handleOnSelectReasonCode = (
    value: string,
    currentActionExecutionReasons: any[],
    setInputOtherVisible: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    const is_other = currentActionExecutionReasons?.find(
      (obj) => obj.id === value
    )?.name;
    if (is_other.toLowerCase() === "other") {
      setInputOtherVisible(true);
    } else {
      setInputOtherVisible(false);
    }
  };
  const getEnums = async (
    setValueEnumPredefinedAction: React.Dispatch<React.SetStateAction<any>>,
    setValueEnumPredefinedExecutions: React.Dispatch<React.SetStateAction<any>>
  ): Promise<void> => {
    await executionsServices
      .getResources({ pageSize: 10000 }, ["action", "model"])
      .then((res) => {
        setExecConfs(res?.items);
        //  Executions enum
        const updatedEnumExecutions: { [key: string]: { text: string } } = {};
        res?.items?.forEach((curr: any) => {
          updatedEnumExecutions[curr.id] = { text: curr.name };
        });

        setValueEnumPredefinedExecutions(updatedEnumExecutions);
      });
    await actionsServices.getResources({ pageSize: 10000 }).then((res) => {
      setActionConfs(res?.items);
      // Actions enum
      const updatedEnumAction: { [key: string]: { text: string } } = {};
      res?.items?.forEach((curr: any) => {
        updatedEnumAction[curr.id] = { text: curr.name };
      });

      setValueEnumPredefinedAction(updatedEnumAction);
    });
  };
  return {
    getCurrentRiskActions,
    getCurrentActionFormatUI,
    extractPositionAttributes,
    stopRisk,
    getRunningRiskById,
    filterRunningOpenActions,
    filterRunningOpenActionsAppend,
    filterRunningRisksHistory,
    rejectActionOrExecution,
    showNotificationAccept,
    acceptActionOrExecution,
    handleReasonCodeModal,
    showDrawer,
    closeAddReasonCodeModal,
    onClose,
    getAccessAcceptReject,
    reasonstoLowerCase,
    filterCurrentActionreasons,
    handleOnSelectReasonCode,
    getEnums,
  };
};

export const acceptRejectAccess = (currentAction: any, selectedRisk: any) => {
  if (selectedRisk?.status === STATUS.COMPLETE) {
    return false;
  } else {
    if (
      currentAction?.status !== STATUS.OPEN &&
      currentAction?.status !== STATUS.WAIT_FOR_EXECUTION
    ) {
      return false;
    } else {
      if (
        selectedRisk?.current_action?.id === currentAction?.id &&
        (currentAction?.status === STATUS.WAIT_FOR_EXECUTION ||
          currentAction?.status === STATUS.OPEN)
      ) {
        return true;
      }
    }
  }
  return false;
};
