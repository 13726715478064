// src/ChatComponent.tsx

import React, { useContext, useEffect, useState } from "react";
import {
  chatWithGPT,
  createThread,
  retrieveAssistant,
} from "../../../services/open-ai.service";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Input,
  Layout,
  MenuProps,
  Row,
  Space,
  Spin,
  Typography,
} from "antd";
import { Content } from "antd/es/layout/layout";
import {
  ChartLine,
  ChartPieSlice,
  CheckSquareOffset,
  CurrencyCircleDollar,
  ExoBrain,
  ProcurementIcon,
} from "../../../assets/images";
import {
  ArrowRightOutlined,
  BulbOutlined,
  DownOutlined,
  EyeOutlined,
  FileTextOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  RocketOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import "./chat.css";
import { FiArrowUpRight } from "react-icons/fi";
import { MainContext } from "../../../utils/context";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store/store";
import { getTranslation } from "../../../utils/transaltion";
import { Link } from "react-router-dom";
import CustomIcon from "../codification/components/CustomIcon";
import { t } from "i18next";
import { HAS_GPT } from "../../../constants/server";
import SetupInstructions from "./Content";
import OpenAI from "openai";

const { Title, Text } = Typography;

// Define the type for a chat message
interface ChatMessage {
  role: "user" | "bot";
  content: any;
  assistant: AssistantType;
}

const cardData = [
  {
    title: "Why was the recommendation for procurement rejected?",
    icon: <FileTextOutlined style={{ fontSize: "20px", color: "#0056A6" }} />,
    assistant: "MONITOR_ACTIONS",
  },
  {
    title: "Can you explain the rationale behind the fulfillment action?",
    icon: <SmileOutlined style={{ fontSize: "20px", color: "#0056A6" }} />,
    assistant: "MONITOR_ACTIONS",
  },
  {
    title: "What is the context for the inventory recommendation?",
    icon: <BulbOutlined style={{ fontSize: "20px", color: "#0056A6" }} />,
    assistant: "MONITOR_ACTIONS",
  },
  {
    title:
      "Who rejected the recent recommendation about stock deployment and why?",
    icon: <RocketOutlined style={{ fontSize: "20px", color: "#0056A6" }} />,
    assistant: "MONITOR_ACTIONS",
  },
  {
    title: "What support do you need with My Exobrain?",
    icon: <FileTextOutlined style={{ fontSize: "20px", color: "#0056A6" }} />,
    assistant: "HELP",
  },
  {
    title: "Can you specify the area where you need help?",
    icon: <SmileOutlined style={{ fontSize: "20px", color: "#0056A6" }} />,
    assistant: "HELP",
  },
  {
    title: "Do you need a summary of the setup instructions?",
    icon: <BulbOutlined style={{ fontSize: "20px", color: "#0056A6" }} />,
    assistant: "HELP",
  },
  {
    title: "Is there any specific data requirement you need?",
    icon: <RocketOutlined style={{ fontSize: "20px", color: "#0056A6" }} />,
    assistant: "HELP",
  },
];

type AssistantType = "MONITOR_ACTIONS" | "HELP";

const ChatComponent: React.FC = () => {
  const globalContext = useContext(MainContext);
  const copilotList = useSelector(
    (state: RootState) => state.userCopilotReducer
  );

  const [input, setInput] = useState<string>("");
  const [chatLog, setChatLog] = useState<ChatMessage[]>([]);
  const [chatLoading, setChatLoading] = useState<boolean>(false);
  const [selectedAssistant, setSelectedAssistant] =
    useState<AssistantType>("MONITOR_ACTIONS");

  const [helpAssistant, setHelpAssistant] =
    useState<OpenAI.Beta.Assistants.Assistant>();
  const [helpThread, setHelpThread] = useState<OpenAI.Beta.Threads.Thread>();
  const [monitorAssistant, setMonitorAssistant] =
    useState<OpenAI.Beta.Assistants.Assistant>();
  const [monitorThread, setMonitorThread] =
    useState<OpenAI.Beta.Threads.Thread>();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
  };

  useEffect(() => {
    initAssistant();
  }, [selectedAssistant]);

  const initAssistant = async () => {
    if (selectedAssistant === "MONITOR_ACTIONS") {
      if (!monitorThread) {
        const thread = await createThread();
        setMonitorThread(thread);
      }
      if (!monitorAssistant) {
        const assistant = await retrieveAssistant(selectedAssistant);
        setMonitorAssistant(assistant);
      }
    } else {
      if (!helpThread) {
        const thread = await createThread();
        setHelpThread(thread);
      }
      if (!helpAssistant) {
        const assistant = await retrieveAssistant(selectedAssistant);
        setHelpAssistant(assistant);
      }
    }
  };

  const handleSendMessage = async (data?: string) => {
    let content = "";
    setChatLoading(true);
    if (data) {
      if (!data.trim()) return;
      content = data;
    } else {
      if (!input.trim()) return; // Prevent sending empty messages
      content = input;
    }
    console.log(content);
    const userMessage: ChatMessage = {
      role: "user",
      content: content,
      assistant: selectedAssistant,
    };
    setChatLog([...chatLog, userMessage]);
    setInput("");

    await initAssistant();
    const response =
      selectedAssistant === "MONITOR_ACTIONS"
        ? await chatWithGPT(content, monitorAssistant!, monitorThread!)
        : await chatWithGPT(content, helpAssistant!, helpThread!);
    const botMessage: ChatMessage = {
      role: "bot",
      content: response,
      assistant: selectedAssistant,
    };
    setChatLog([...chatLog, userMessage, botMessage]);
    setChatLoading(false);
  };

  return (
    <div>
      <Layout className="app-container">
        <Content style={{ padding: "60px" }}>
          <Row gutter={[16, 16]} justify="center">
            <div
              className="custom-text"
              style={{
                textAlign: "center",
                fontFamily: "Century Gothic Paneuropean",
                gap: "12px",
              }}
            >
              <Title
                level={2}
                style={{
                  fontWeight: "600",
                  color: "#333",
                  fontSize: "62px",
                  marginBlockEnd: "10px",
                  height: "84px",
                }}
              >
                <span className="gradient-text">
                  {`${t("HELLO")} ${
                    globalContext?.context?.user?.displayname || ""
                  }!`}
                </span>
              </Title>
              <Text>
                <span className="sub-title">
                  {t("LANDING_PAGE_HOW_CAN_I_ASSIST_YOU")}
                </span>
              </Text>
            </div>
          </Row>
          <br></br>
          {HAS_GPT ? (
            <div>
              <Row gutter={[16, 16]} justify="center">
                <Col>
                  <div className="custom-button-container">
                    {/* First Button */}
                    <Button
                      icon={<EyeOutlined />}
                      className={
                        selectedAssistant === "MONITOR_ACTIONS"
                          ? "custom-button monitor-actions-btn"
                          : "custom-button help-btn"
                      }
                      onClick={() => {
                        setSelectedAssistant("MONITOR_ACTIONS");
                      }}
                    >
                      Monitor actions?
                    </Button>

                    {/* Second Button */}
                    <Button
                      icon={<InfoCircleOutlined />}
                      className={
                        selectedAssistant === "HELP"
                          ? "custom-button monitor-actions-btn"
                          : "custom-button help-btn"
                      }
                      onClick={() => {
                        setSelectedAssistant("HELP");
                      }}
                    >
                      Need more help?
                    </Button>
                  </div>
                </Col>
              </Row>

              <br></br>
              <Row gutter={[16, 16]} style={{ padding: "20px" }}>
                {cardData
                  .filter((data) => data.assistant === selectedAssistant)
                  .map((card, index) => (
                    <Col
                      key={index}
                      xs={24}
                      sm={12}
                      md={6}
                      className="card-container"
                    >
                      <Card
                        className="card"
                        hoverable
                        onClick={() => {
                          handleSendMessage(card?.title);
                        }}
                      >
                        <div className="card-title">{card.title}</div>
                        {/* <div className="card-icon">{card.icon}</div> */}
                      </Card>
                    </Col>
                  ))}
              </Row>
              <br></br>
              {chatLog
                ?.filter((data) => data.assistant === selectedAssistant)
                ?.map((msg, index) => (
                  <Row key={index} className={msg.role}>
                    <SetupInstructions content={msg.content} role={msg.role} />
                  </Row>
                ))}
              {chatLoading && (
                <Row justify="center">
                  <Spin
                    style={{
                      marginBlock: "20px",
                    }}
                    indicator={<LoadingOutlined spin />}
                    size="large"
                    spinning={chatLoading}
                    tip={"Processing..."}
                  >
                    <br></br>
                    <span
                      style={{
                        padding: 50,
                        marginBlock: "20px",
                      }}
                    >
                      {""}
                    </span>
                    <br></br>
                    <br></br>
                    <br></br>
                  </Spin>
                </Row>
              )}

              <br></br>
              <Row>
                <Col span={24}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "10px",
                      backgroundColor: "#eaeaea",
                      borderRadius: "8px",
                      marginInline: "15px",
                    }}
                  >
                    <Input
                      placeholder="Enter a prompt here"
                      style={{
                        border: "none",
                        boxShadow: "none",
                        backgroundColor: "transparent",
                        width: "100%",
                        marginRight: "10px",
                      }}
                      value={input}
                      disabled={chatLoading}
                      onChange={handleInputChange}
                      onPressEnter={() => handleSendMessage()}
                    />
                    <FiArrowUpRight
                      style={{
                        fontSize: "24px",
                        color: "#999",
                        backgroundColor: "#fff",
                        borderRadius: "8px",
                        cursor: "pointer",
                      }}
                      onClick={() => !chatLoading && handleSendMessage()}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <div>
              <Row gutter={[16, 16]} style={{ padding: "20px" }}>
                <Col key={1} xs={24} sm={12} md={6} className="card-container">
                  <Card
                    className="card"
                    hoverable
                    onClick={() => {
                      // handleSendMessage(card?.title);
                    }}
                  >
                    <div className="card-title">
                      {t("LANDING_PAGE_TAKE_ACTIONS")}
                    </div>
                    <Dropdown
                      className="card-icon"
                      menu={{
                        items: [
                          ...copilotList.map((copilot: any, index: number) => {
                            return {
                              icon: (
                                <CustomIcon
                                  name={copilot?.uiConfiguration?.iconName}
                                  size={"30px"}
                                />
                              ),
                              label: (
                                <Link
                                  to={`/menu/${copilot?.name
                                    ?.toString()
                                    ?.toLowerCase()}`}
                                >
                                  {getTranslation(copilot?.name, "data")}
                                </Link>
                              ),
                            };
                          }),
                        ],
                        onClick: () => {},
                      }}
                    >
                      <Space>
                        <CheckSquareOffset />
                      </Space>
                    </Dropdown>
                  </Card>
                </Col>
                <Col key={2} xs={24} sm={12} md={6} className="card-container">
                  <Card
                    className="card"
                    hoverable
                    onClick={() => {
                      // handleSendMessage(card?.title);
                    }}
                  >
                    <div className="card-title">
                      {t("LANDING_PAGE_MONITOR_ACTIONS")}
                    </div>
                    <Dropdown
                      className="card-icon"
                      menu={{
                        items: [
                          ...copilotList.map((copilot: any, index: number) => {
                            return {
                              icon: (
                                <CustomIcon
                                  name={copilot?.uiConfiguration?.iconName}
                                  size={"30px"}
                                />
                              ),
                              label: (
                                <Link
                                  to={`/menu/${copilot?.name
                                    ?.toString()
                                    ?.toLowerCase()}/action-monitoring`}
                                >
                                  {getTranslation(copilot?.name, "data")}
                                </Link>
                              ),
                            };
                          }),
                        ],
                        onClick: () => {},
                      }}
                    >
                      <Space>
                        <ChartPieSlice />
                      </Space>
                    </Dropdown>
                  </Card>
                </Col>
                <Col key={3} xs={24} sm={12} md={6} className="card-container">
                  <Card
                    className="card"
                    hoverable
                    onClick={() => {
                      // handleSendMessage(card?.title);
                    }}
                  >
                    <div className="card-title">
                      {t("LANDING_PAGE_AUTOMATE_ACTIONS")}
                    </div>
                    <Dropdown
                      className="card-icon"
                      menu={{
                        items: [
                          ...copilotList.map((copilot: any, index: number) => {
                            return {
                              icon: (
                                <CustomIcon
                                  name={copilot?.uiConfiguration?.iconName}
                                  size={"30px"}
                                />
                              ),
                              label: (
                                <Link
                                  to={`/menu/${copilot?.name
                                    ?.toString()
                                    ?.toLowerCase()}/action-analysis`}
                                >
                                  {getTranslation(copilot?.name, "data")}
                                </Link>
                              ),
                            };
                          }),
                        ],
                        onClick: () => {},
                      }}
                    >
                      <Space>
                        <ChartLine />
                      </Space>
                    </Dropdown>
                  </Card>
                </Col>
                <Col key={4} xs={24} sm={12} md={6} className="card-container">
                  <Card
                    className="card"
                    hoverable
                    onClick={() => {
                      // handleSendMessage(card?.title);
                    }}
                  >
                    <div className="card-title">
                      {t("LANDING_PAGE_UNDERSTAND_BENEFITS")}
                    </div>
                    <Dropdown
                      className="card-icon"
                      menu={{
                        items: [
                          ...copilotList.map((copilot: any, index: number) => {
                            return {
                              icon: (
                                <CustomIcon
                                  name={copilot?.uiConfiguration?.iconName}
                                  size={"30px"}
                                />
                              ),
                              label: (
                                <Link
                                  to={`/menu/${copilot?.name
                                    ?.toString()
                                    ?.toLowerCase()}/action-roi`}
                                >
                                  {getTranslation(copilot?.name, "data")}
                                </Link>
                              ),
                            };
                          }),
                        ],
                        onClick: () => {},
                      }}
                    >
                      <Space>
                        <CurrencyCircleDollar />
                      </Space>
                    </Dropdown>
                  </Card>
                </Col>
              </Row>
            </div>
          )}
        </Content>
      </Layout>
    </div>
  );
};

export default ChatComponent;
