import { useContext, useEffect, useState } from "react";
import { message, Modal, notification } from "antd";
import { useTranslation } from "react-i18next";

import LicensesTable from "./licenses-table/LicensesTable";
import { MainContext } from "../../../utils/context";
import LicenseModal from "./LicenseModal";
import { getTranslation } from "../../../utils/transaltion";
import { LicenseItem } from "../../../interfaces/license";
import LicensesService from "../../../services/licenses.service";
import { WORK_MODE } from "../../../constants/workModes";
import { PAGES_SIZE } from "../../../constants/pagination";
import handleApiError from "../../../services/functions/handle-api-errors/handleApiError";
import { REQUEST } from "../../../services/functions/handle-api-errors/const";

export default function Licenses() {
  const { t } = useTranslation();
  const globalContext = useContext(MainContext);
  const formatDate = globalContext?.context?.orga?.formatDate || "YYYY/MM/DD";

  const [rows, setRows] = useState<LicenseItem[]>([]);
  const [getLoading, setGetLoading] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [openModal, setOpenModal] = useState(false);
  const [workModeModal, setWorkModeModal] = useState(WORK_MODE.CREATE);
  const [currentRecord, setCurrentRecord] = useState({});

  useEffect(() => {
    filterLicenses({ current: currentPage, pageSize: PAGES_SIZE });
  }, [currentPage]);
  if (!globalContext) {
    return <></>;
  }
  const licensesService = new LicensesService(
    globalContext.context,
    globalContext.setContext
  );
  const filterLicenses = async (params: any, filters?: any) => {
    setCurrentPage(params?.current);
    setGetLoading(true);
    await licensesService
      .filterResource(params, filters)
      .then(async (response) => {
        setRows(response?.items);
        setTotal(response?.total);
      })
      .catch((err) => {
        handleApiError(err);
      });
    setGetLoading(false);
  };
  const createLicense = async (license: any) => {
    setSaveLoading(true);
    const newLicense = {
      start: license?.start,
      expiry: license?.expiry,
      organization: license?.organization?.id,
      copilots: license?.copilots?.map((copilot: any) => copilot?.id),
    };
    await licensesService
      .createResource(newLicense)
      .then(async () => {
        filterLicenses({ current: currentPage, pageSize: PAGES_SIZE });
        closeLicenseModal();
        setSaveLoading(false);
        message.success(getTranslation("LICENSE_CREATED_SUCCESSFULLY", ""));
      })
      .catch((err) => {
        handleApiError(err, REQUEST.CREATE);
        setSaveLoading(false);
      });
    setGetLoading(false);
  };
  const updateLicense = async (id: string, license: any) => {
    setSaveLoading(true);
    const newLicense = {
      start: license?.start,
      expiry: license?.expiry,
      copilots: license?.copilots?.map((copilot: any) => copilot?.id),
    };
    await licensesService
      .updateResource(id, newLicense)
      .then(async () => {
        filterLicenses({ current: currentPage, pageSize: PAGES_SIZE });
        closeLicenseModal();
        message.success(getTranslation("LICENSE_UPDATED_SUCCESSFULLY", ""));
        setSaveLoading(false);
      })
      .catch((err) => {
        handleApiError(err, REQUEST.UPDATE);
        // message.error(t("errors:UPDATE_FAILED"));
        setSaveLoading(false);
      });
    setGetLoading(false);
  };
  const deleteLicense = async (id: string) => {
    setGetLoading(true);
    await licensesService
      .deleteResource(id)
      .then(async () => {
        filterLicenses({ current: currentPage, pageSize: PAGES_SIZE });
        message.success(getTranslation("LICENSE_DELETED_SUCCESSFULLY", ""));
      })
      .catch((err) => {
        handleApiError(err, REQUEST.DELETE);
      });
    setGetLoading(false);
  };

  /* Modal open modes */
  const createLicenseModal = () => {
    setCurrentRecord({});
    setWorkModeModal(WORK_MODE.CREATE);
    setOpenModal(true);
  };
  const updateLicenseModal = (license: any) => {
    setCurrentRecord(license);
    setWorkModeModal(WORK_MODE.UPDATE);
    setOpenModal(true);
  };
  const viewLicenseModal = (license: any) => {
    setCurrentRecord(license);
    setWorkModeModal(WORK_MODE.VIEW);
    setOpenModal(true);
  };
  const closeLicenseModal = () => {
    setOpenModal(false);
  };
  return (
    <>
      <LicensesTable
        rows={rows}
        total={total}
        currentPage={currentPage}
        getLoading={getLoading}
        filterLicenses={filterLicenses}
        /* on finish crud actions */
        deleteLicense={deleteLicense}
        /* Modal handling */
        createLicenseModal={createLicenseModal}
        updateLicenseModal={updateLicenseModal}
        viewLicenseModal={viewLicenseModal}
        /*  */
        formatDate={formatDate}
      />
      <Modal
        destroyOnClose={true}
        title={
          workModeModal === WORK_MODE.CREATE
            ? "Create license"
            : workModeModal === WORK_MODE.UPDATE
            ? "Update license"
            : "License"
        }
        open={openModal}
        onCancel={closeLicenseModal}
        width={800}
        footer={null}
      >
        <LicenseModal
          workModeModal={workModeModal}
          currentRecord={currentRecord}
          saveLoading={saveLoading}
          createLicense={createLicense}
          updateLicense={updateLicense}
          closeLicenseModal={closeLicenseModal}
          formatDate={formatDate}
        />
      </Modal>
    </>
  );
}
