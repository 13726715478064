import { CATEGORY_VIEWS } from "../../../../constants/categoryviews";

export const procurement_Configs = [
  {
    name: "FULL_TRUCK_LOAD",
    category: {
      ACTION_DETAILS: {
        title: "ACTION_DETAILS",
        views: {
          inputs: [
            {
              title: "Details",
              column: [
                "MODE_OF_TRANSPORT",
                "NUMBER_OF_TRUCKS",
                "TOTAL_FREIGHT_CO2_EMISSION",
                "TOTAL_FREIGHT_COST",
                "SHIPPING_LOCATION_CODE",
                "SHIPPING_DATE",
                "RECEIVING_LOCATION_CODE",
              ],
              data: { ACTION_DETAILS: "$.DATA.ACTION_DETAILS" },
            },
          ],
          dynamic_table: [
            {
              title: "Number of trucks per load type",
              data: {
                NUMBER_OF_TRUCKS_PER_LOAD_TYPE:
                  "$.DATA.ACTION_DETAILS.NUMBER_OF_TRUCKS_PER_LOAD_TYPE",
              },
              columns: {
                NUMBER_OF_TRUCKS: "$.NUMBER_OF_TRUCKS",
                LOAD_TYPE: "$.LOAD_TYPE",
              },

              type: CATEGORY_VIEWS.OTHER,
            },
            {
              title: "Number of trucks per truck type",
              data: {
                NUMBER_OF_TRUCKS_PER_TRUCK_TYPE:
                  "$.DATA.ACTION_DETAILS.NUMBER_OF_TRUCKS_PER_TRUCK_TYPE",
              },
              columns: {
                NUMBER_OF_TRUCKS: "$.NUMBER_OF_TRUCKS",
                TRUCK_TYPE: "$.TRUCK_TYPE",
              },

              type: CATEGORY_VIEWS.OTHER,
            },
          ],
        },
      },

      RISK_IMPACTS: {
        title: "RISK_IMPACT",
        views: {
          inputs: [
            {
              title: "Impact",
              column: [
                "ACTION_DUE_DATE",
                "NUMBER_OF_TRUCKS",
                "TOTAL_FREIGHT_CO2_EMISSION",
                "TOTAL_FREIGHT_COST",
              ],
              data: { IMPACT: "$.DATA.IMPACT" },
            },
          ],
          dynamic_table: [
            {
              title: "Truck load ",
              data: {
                TRUCK_PRODUCT_LOAD_LIST: "$.DATA.TRUCK_PRODUCT_LOAD_LIST",
              },
              columns: {
                TRUCK_ID: "$.TRUCK_ID",
                LOAD_TYPE: "$.LOAD_TYPE",
                TRUCK_TYPE: "$.TRUCK_TYPE",
                MAXIMUM_TRUCK_LOAD_CAPACITY: "$.MAXIMUM_TRUCK_LOAD_CAPACITY",
                MINIMUM_TRUCK_LOAD_CAPACITY: "$.MINIMUM_TRUCK_LOAD_CAPACITY",

                TRUCK_LOADED_PALLET_QUANTITY: "$.TRUCK_LOADED_PALLET_QUANTITY",
                TRUCK_REMAINING_PALLET_CAPACITY:
                  "$.TRUCK_REMAINING_PALLET_CAPACITY",
              },

              type: CATEGORY_VIEWS.OTHER,
            },
            {
              title: "Product load",
              data: {
                TRUCK_PRODUCT_LOAD_LIST: "$.DATA.TRUCK_PRODUCT_LOAD_LIST",
              },
              columns: {
                TRUCK_ID: "$.TRUCK_ID",
                ITEM_CODE: "$.ITEM_CODE",
                FREIGHT_COST: "$.FREIGHT_COST",

                PRODUCT_LOADED_PALLET_QUANTITY:
                  "$.PRODUCT_LOADED_PALLET_QUANTITY",
              },

              type: CATEGORY_VIEWS.OTHER,
            },
          ],
          flow_chart: [
            {
              title: "Transfer order",
              type: "ARRAY",
              data: { TRANSFER_ORDER: "$.DATA.TRANSFER_ORDER" },

              startNodes: [
                {
                  path: {
                    SHIPPING_LOCATION_CODE: `$.DATA.MASTER.LOCATION_MASTER.LOCATION_ID`,
                  },
                  valueField: "LOCATION_CITY",
                },
                "SHIPPING_DATE",
              ],
              endNodes: [
                {
                  path: {
                    RECEIVING_LOCATION_CODE: `$.DATA.MASTER.LOCATION_MASTER.LOCATION_ID`,
                  },
                  valueField: "LOCATION_CITY",
                },
                "DELIVERY_DATE",
                // "SHIPPING_DATE",
              ],

              details: ["TRUCK_ID", "ITEM_CODE"],
            },
          ],
        },
      },
      ACTION_CONTEXT_VIEW: {
        title: "Action_details",
        views: {
          dynamic_table: [
            {
              title: "Transfer Products list",
              data: {
                CONTAINER_PRODUCTS_LIST: "$.DATA.TRANSFER_PRODUCTS_LIST",
              },
              columns: {
                ITEM_CODE: "$.ITEM_CODE",
                ITEM_TYPE: "$.ITEM_TYPE",
                FREIGHT_COST: "$.FREIGHT_COST",
                SHIPPING_LOCATION_CODE: "$.SHIPPING_LOCATION_CODE",
                SHIPPING_DATE: "$.SHIPPING_DATE",
                RECEIVING_LOCATION_CODE: "$.RECEIVING_LOCATION_CODE",
                TRANSFER_QUANTITY_IN_PALLETS: "$.TRANSFER_QUANTITY_IN_PALLETS",
              },

              type: CATEGORY_VIEWS.OTHER,
            },
          ],
          map: [
            {
              title: "Internal routes",
              type: "ARRAY",
              data: { DEPLOYMENT_ROUTE: "$.DATA.INTERNAL_ROUTES" },
              receiving_location: {
                RECEIVING_LOCATION_CODE: `$.DATA.MASTER.LOCATION_MASTER.LOCATION_ID`,
              },
              shipping_location: {
                SHIPPING_LOCATION_CODE: `$.DATA.MASTER.LOCATION_MASTER.LOCATION_ID`,
              },
              receiving_location_city: "LOCATION_CITY",
              shipping_location_city: "LOCATION_CITY",
              details: [
                "LEAD_TIME_DAYS",
                "MODE_OF_TRANSPORTATION",
                "DISTANCE_KM",
                "ROUTE_DESCRIPTION",
                "ROUTE_CODE",
                "COST_PER_UNIT_CURRENCY",
                "CO2_EMISSION_PER_KG_SHIPPED",
              ],
            },
          ],
        },
      },
      ERROR_WARNING_VIEW: {
        title: "ERRORS_WARNING",
        views: {
          dynamic_table: [
            {
              title: "Errors & warnings",
              data: {
                ERROR: "$.DATA.ERRORS.ERROR",
                INFO: "$.DATA.ERRORS.INFO",
                WARNING: "$.DATA.ERRORS.WARNING",
              },
              columns: {
                CODE: "$.CODE",
                DETAILS: "$.DETAILS.DATA",
                MODULE: "$.MODULE",
              },

              type: CATEGORY_VIEWS.ERROR_WARNING_VIEW,
            },
          ],
        },
      },
      OTHER_OPTIONS: false,
    },
  },
  {
    name: "FULL_CONTAINER_DEPLOYMENT",
    category: {
      ACTION_DETAILS: {
        title: "ACTION_DETAILS",
        views: {
          inputs: [
            {
              title: "Details",
              column: [
                "CONTAINER_NUMBER",
                "DESTINATION_LOCATION_CODE",
                "ORIGIN_LOCATION_CODE",
                "FREIGHT_COST",
                "FREIGHT_CO2_EMISSION",
                "SHIPPING_DATE",
                "RECEIVING_DATE",
              ],
              data: { ACTION_DETAILS: "$.DATA.ACTION_DETAILS" },
            },
          ],
        },
      },

      RISK_IMPACTS: {
        title: "RISK_IMPACT",
        views: {
          inputs: [
            {
              title: "Impact",
              column: null,
              data: { IMPACT: "$.DATA.IMPACT" },
            },
          ],
          flow_chart: [
            {
              title: "Transfer order",
              type: "OBJECT",
              data: { TRANSFER_ORDER: "$.DATA.TRANSFER_ORDER" },

              startNodes: [
                {
                  path: {
                    ORIGIN_LOCATION_CODE: `$.DATA.MASTER.LOCATION_MASTER.LOCATION_ID`,
                  },
                  valueField: "LOCATION_CITY",
                },
                "SHIPPING_DATE_AT_ORIGIN",
              ],
              endNodes: [
                {
                  path: {
                    DESTINATION_LOCATION_CODE: `$.DATA.MASTER.LOCATION_MASTER.LOCATION_ID`,
                  },
                  valueField: "LOCATION_CITY",
                },
                "RECEIVING_DATE_AT_DESTINATION",
              ],

              details: ["CONTAINER_NUMBER", "TRANSPORTATION_MODE"],
            },
          ],
        },
      },
      ACTION_CONTEXT_VIEW: {
        title: "Action_details",
        views: {
          map: [
            {
              title: "Deployment routes",
              type: "ARRAY",
              data: { DEPLOYMENT_ROUTE: "$.DATA.DEPLOYMENT_ROUTE" },
              receiving_location: {
                ORIGIN_LOCATION_CODE: `$.DATA.MASTER.LOCATION_MASTER.LOCATION_ID`,
              },
              shipping_location: {
                DESTINATION_LOCATION_CODE: `$.DATA.MASTER.LOCATION_MASTER.LOCATION_ID`,
              },
              receiving_location_city: "LOCATION_CITY",
              shipping_location_city: "LOCATION_CITY",
              details: [
                "LEAD_TIME_DAYS",
                "TRANSPORTATION_MODE",
                "DISTANCE_KM",
                "ROUTE_DESCRIPTION",
                "ROUTE_CODE",
                "COST_PER_UNIT_CURRENCY",
                "CO2_EMISSION_PER_KG_SHIPPED",
                "SUPPLIER_CODE",
              ],
            },
          ],
          dynamic_table: [
            {
              title: "Products list",
              data: {
                CONTAINER_PRODUCTS_LIST: "$.DATA.CONTAINER_PRODUCTS_LIST",
              },
              columns: {
                CONTAINER_NUMBER: "$.CONTAINER_NUMBER",
                ITEM_CODE: "$.ITEM_CODE",
                ORDER_VALUE: "$.ORDER_VALUE",
                QUANTITY_IN_PALLETS: "$.QUANTITY_IN_PALLETS",
                SUPPLIER_CODE: "$.SUPPLIER_CODE",
                QUANTITY_IN_UNITS: "$.QUANTITY_IN_UNITS",
              },

              type: CATEGORY_VIEWS.OTHER,
            },
          ],
          dualAxes_chart: [
            // {
            //   title: "Current Inventory & New inventory",
            //   type: "ARRAY",
            //   dualAxis: true,
            //   dataSetY1: {
            //     data: {
            //       CURRENT_AVAILABLE_INVENTORY: {
            //         path: "$.DATA.CURRENT_AVAILABLE_INVENTORY",
            //         groupe: "ITEM_CODE",
            //         isgroupe: true,

            //         seriesFieldY1: [
            //           // "ON_HAND_VALUE",
            //           // "SHORTAGE_VALUE",
            //           "QUANTITY_ON_HAND",
            //           "DEMAND",
            //         ],
            //       },
            //       NEW_AVAILABLE_INVENTORY: {
            //         path: "$.DATA.NEW_AVAILABLE_INVENTORY",
            //         groupe: "ITEM_CODE",
            //         isgroupe: true,

            //         seriesFieldY1: [
            //           // "ON_HAND_VALUE",
            //           // "NEW_EXCESS_VALUE",
            //           "QUANTITY_ON_HAND",
            //         ],
            //       },
            //     },
            //     yTitle1: "Values",
            //   },
            //   dataSetY2: {
            //     data: {
            //       CURRENT_AVAILABLE_INVENTORY: {
            //         groupe: "ITEM_CODE",
            //         isgroupe: true,

            //         path: "$.DATA.CURRENT_AVAILABLE_INVENTORY",
            //         seriesFieldY2: ["DAYS_OF_SUPPLY"], // can be more then one
            //       },
            //       NEW_AVAILABLE_INVENTORY: {
            //         groupe: "ITEM_CODE",
            //         isgroupe: true,

            //         path: "$.DATA.NEW_AVAILABLE_INVENTORY",
            //         seriesFieldY2: ["DAYS_OF_SUPPLY"],
            //       },
            //     },

            //     yTitle2: "D.O.S",
            //   },
            // },
            // {
            //   title: "Current Inventory ",
            //   type: "ARRAY",
            //   dualAxis: true,
            //   dataSetY1: {
            //     data: {
            //       CURRENT_AVAILABLE_INVENTORY: {
            //         path: "$.DATA.CURRENT_AVAILABLE_INVENTORY",
            //         groupe: "ITEM_CODE",
            //         isgroupe: false,
            //         seriesFieldY1: [
            //           // "ON_HAND_VALUE",
            //           // "SHORTAGE_VALUE",
            //           "QUANTITY_ON_HAND",
            //           "DEMAND",
            //         ],
            //       },
            //     },
            //     yTitle1: "Values",
            //   },
            //   dataSetY2: {
            //     data: {
            //       CURRENT_AVAILABLE_INVENTORY: {
            //         groupe: "ITEM_CODE",
            //         isgroupe: false,
            //         path: "$.DATA.CURRENT_AVAILABLE_INVENTORY",
            //         seriesFieldY2: ["DAYS_OF_SUPPLY"], // can be more then one
            //       },
            //     },

            //     yTitle2: "D.O.S",
            //   },
            // },
            // {
            //   title: "New inventory",
            //   type: "ARRAY",
            //   dualAxis: true,
            //   dataSetY1: {
            //     data: {
            //       NEW_AVAILABLE_INVENTORY: {
            //         path: "$.DATA.NEW_AVAILABLE_INVENTORY",
            //         groupe: "ITEM_CODE",
            //         isgroupe: false,
            //         seriesFieldY1: [
            //           // "ON_HAND_VALUE",
            //           // "NEW_EXCESS_VALUE",
            //           "QUANTITY_ON_HAND",
            //         ],
            //       },
            //     },
            //     yTitle1: "Values",
            //   },
            //   dataSetY2: {
            //     data: {
            //       NEW_AVAILABLE_INVENTORY: {
            //         groupe: "ITEM_CODE",
            //         isgroupe: false,
            //         path: "$.DATA.NEW_AVAILABLE_INVENTORY",
            //         seriesFieldY2: ["DAYS_OF_SUPPLY"],
            //       },
            //     },

            //     yTitle2: "D.O.S",
            //   },
            // },
            {
              title: "Current Inventory & new inventory",
              type: "ARRAY",
              graphByValueField: "ITEM_CODE",
              dualAxis: true,
              dataSetY1: {
                additionalGroupingField: "DEMAND",

                data: {
                  CURRENT_AVAILABLE_INVENTORY: {
                    path: "$.DATA.CURRENT_AVAILABLE_INVENTORY",

                    isgroupe: false,
                    seriesFieldY1: [
                      // "ON_HAND_VALUE",
                      // "SHORTAGE_VALUE",
                      "QUANTITY_ON_HAND",
                      "DEMAND",
                    ],
                  },
                  NEW_AVAILABLE_INVENTORY: {
                    path: "$.DATA.NEW_AVAILABLE_INVENTORY",

                    isgroupe: false,

                    seriesFieldY1: [
                      // "ON_HAND_VALUE",
                      // "NEW_EXCESS_VALUE",
                      "QUANTITY_ON_HAND",
                    ],
                  },
                },
                yTitle1: "Quantity",
              },
              dataSetY2: {
                data: {
                  CURRENT_AVAILABLE_INVENTORY: {
                    groupe: "ITEM_CODE",
                    isgroupe: true,

                    path: "$.DATA.CURRENT_AVAILABLE_INVENTORY",
                    seriesFieldY2: ["DAYS_OF_SUPPLY"], // can be more then one
                  },
                  NEW_AVAILABLE_INVENTORY: {
                    groupe: "ITEM_CODE",
                    isgroupe: true,

                    path: "$.DATA.NEW_AVAILABLE_INVENTORY",
                    seriesFieldY2: ["DAYS_OF_SUPPLY"],
                  },
                },

                yTitle2: "D.O.S",
              },
            },
          ],
        },
      },
      ERROR_WARNING_VIEW: {
        title: "ERRORS_WARNING",
        views: {
          dynamic_table: [
            {
              title: "Errors & warnings",
              data: {
                ERROR: "$.DATA.ERRORS.ERROR",
                INFO: "$.DATA.ERRORS.INFO",
                WARNING: "$.DATA.ERRORS.WARNING",
              },
              columns: {
                CODE: "$.CODE",
                DETAILS: "$.DETAILS.DATA",
                MODULE: "$.MODULE",
              },

              type: CATEGORY_VIEWS.ERROR_WARNING_VIEW,
            },
          ],
        },
      },
      OTHER_OPTIONS: false,
    },
  },
  {
    name: "STOCK_REBALANCING",
    category: {
      ACTION_DETAILS: {
        title: "ACTION_DETAILS",
        views: {
          inputs: [
            {
              title: "details",
              // column: ["DELIVERY_DATE", "FREIGHT_COST"],
              column: null,
              data: { ACTION_DETAILS: "$.DATA.ACTION_DETAILS" },
            },
          ],
        },
      },
      RISK_IMPACTS: {
        title: "RISK_IMPACT",
        views: {
          inputs: [
            {
              title: "Impact",
              column: null,
              data: { IMPACT: "$.DATA.IMPACT" },
            },
          ],
          flow_chart: [
            {
              title: "Transfer order",
              type: "OBJECT",
              data: { TRANSFER_ORDER: "$.DATA.TRANSFER_ORDER" },
              // startNodes: ["RECEIVING_LOCATION", "DELIVERY_DATE"],
              startNodes: [
                {
                  path: {
                    SHIPPING_LOCATION: `$.DATA.MASTER.LOCATION_MASTER.LOCATION_ID`,
                  },
                  valueField: "LOCATION_CITY",
                },
                "SHIP_DATE",
              ],
              endNodes: [
                {
                  path: {
                    RECEIVING_LOCATION: `$.DATA.MASTER.LOCATION_MASTER.LOCATION_ID`,
                  },
                  valueField: "LOCATION_CITY",
                },
                "DELIVERY_DATE",
              ],
              // endNodes: ["SHIPPING_LOCATION", "SHIP_DATE"],
              details: ["ITEM", "QUANTITY"],
            },
          ],
        },
      },
      ACTION_CONTEXT_VIEW: {
        title: "Action_details",
        views: {
          line_chart: [
            {
              title: "inventory & new inventory",
              type: "ARRAY",
              data: {
                INVENTORY: "$.DATA.INVENTORY",
                NEW_INVENTORY_PLAN: "$.DATA.NEW_INVENTORY_PLAN",
              },
              colorConfig: [
                { value: "INVENTORY", color: "#008BC7" },
                { value: "NEW_INVENTORY_PLAN", color: "#c70046" },
              ],
              xField: "DATE",
              yField: "QUANTITY_ON_HAND",
              multiple: true,
            },
          ],
          // column_chart: [
          //   {
          //     title: "inventory ",
          //     type: "ARRAY",
          //     data: {
          //       INVENTORY: "$.DATA.INVENTORY",
          //     },
          //     colorConfig: [{ value: "INVENTORY", color: "#008BC7" }],
          //     xField: "DATE",
          //     yField: "QUANTITY_ON_HAND",
          //   },
          // ],
          // bar_chart: [
          //   {
          //     title: "inventory & new inventory",
          //     type: "ARRAY",
          //     data: {
          //       INVENTORY: "$.DATA.INVENTORY",
          //       NEW_INVENTORY_PLAN: "$.DATA.NEW_INVENTORY_PLAN",
          //     },
          //     colorConfig: [
          //       { value: "INVENTORY", color: "#008BC7" },
          //       { value: "NEW_INVENTORY_PLAN", color: "#c70046" },
          //     ],
          //     yieldField: "DATE",
          //     xField: "QUANTITY_ON_HAND",
          //     dual: true,
          //     grouped: true,
          //   },
          // ],
          map: [
            {
              title: "internal routes",
              type: "OBJECT",
              data: { INTERNAL_ROUTE: "$.DATA.INTERNAL_ROUTE" },
              receiving_location: {
                RECEIVING_LOCATION: `$.DATA.MASTER.LOCATION_MASTER.LOCATION_ID`,
              },
              shipping_location: {
                SHIPPING_LOCATION_CODE: `$.DATA.MASTER.LOCATION_MASTER.LOCATION_ID`,
              },
              receiving_location_city: "LOCATION_CITY",
              shipping_location_city: "LOCATION_CITY",
              details: [
                "CO2_EMISSION_PER_KG_SHIPPED",
                "COST_PER_UNIT_CURRENCY",
                "DISTANCE_KM",
                "MODE_OF_TRANSPORTATION",
                "RECEIVING_LOCATION",
                "ROUTE_CODE",
                "ROUTE_DESCRIPTION",
              ],
            },
          ],
        },
      },
      ERROR_WARNING_VIEW: {
        title: "ERRORS_WARNING",
        views: {
          dynamic_table: [
            {
              title: "Errors & warnings",
              data: {
                ERROR: "$.DATA.ERRORS.ERROR",
                INFO: "$.DATA.ERRORS.INFO",
                WARNING: "$.DATA.ERRORS.WARNING",
              },
              columns: {
                CODE: "$.CODE",
                DETAILS: "$.DETAILS.DATA",
                MODULE: "$.MODULE",
              },

              type: CATEGORY_VIEWS.ERROR_WARNING_VIEW,
            },
          ],
        },
      },

      OTHER_OPTIONS: true,
    },
  },
  {
    name: "ALTERNATE_SUPPLIER",
    category: {
      ACTION_DETAILS: {
        title: "ACTION_DETAILS",
        views: {
          inputs: [
            {
              title: "details",
              column: null,

              data: { ACTION_DETAILS: "$.DATA.ACTION_DETAILS" },
            },
          ],
        },
      },
      RISK_IMPACTS: {
        title: "RISK_IMPACT",
        views: {
          inputs: [
            {
              title: "Impact details",
              column: null,

              data: { IMPACT: "$.DATA.IMPACT" },
            },
          ],
          flow_chart: [
            {
              type: "OBJECT",
              title: "New purchase Order",

              data: { NEW_PURCHASE_ORDER: "$.DATA.NEW_PURCHASE_ORDER" },
              startNodes: [
                {
                  path: {
                    SUPPLIER_CODE: `$.DATA.MASTER.SUPPLIER_MASTER.SUPPLIER_CODE`,
                  },
                  valueField: "SUPPLIER_CITY",
                },
                "SHIPPING_DATE",
              ],
              endNodes: ["RECEIVING_LOCATION_CODE", "DELIVERY_DATE"],
              details: ["ITEM_CODE", "QUANTITY", "ALTERNATE_SUPPLIER_CODE"],
            },
          ],
        },
      },
      ACTION_CONTEXT_VIEW: {
        title: "Action_context_view",
        views: {
          flow_chart: [
            {
              title: "Purchase order",

              type: "OBJECT",
              data: { TRANSFER_ORDER: "$.DATA.PURCHASE_ORDER" },
              startNodes: [
                {
                  path: {
                    SUPPLIER_CODE: `$.DATA.MASTER.SUPPLIER_MASTER.SUPPLIER_CODE`,
                  },
                  valueField: "SUPPLIER_CITY",
                },
                "SHIPPING_DATE",
              ],
              endNodes: ["RECEIVING_LOCATION_CODE", "DELIVERY_DATE"],
              details: ["ITEM_CODE", "QUANTITY", "LINE_NUMBER"],
            },
          ],

          map: [
            {
              title: "Procurement shipping condition",

              type: "OBJECT",
              data: {
                PROCUREMENT_SHIPPING_CONDITION:
                  "$.DATA.PROCUREMENT_SHIPPING_CONDITION",
              },
              receiving_location: {
                RECEIVING_LOCATION_CODE: `$.DATA.MASTER.LOCATION_MASTER.LOCATION_ID`,
              },
              shipping_location: {
                SUPPLIER_CODE: `$.DATA.MASTER.SUPPLIER_MASTER.SUPPLIER_CODE`,
              },
              receiving_location_city: "LOCATION_CITY",
              shipping_location_city: "SUPPLIER_CITY",
              details: [
                "CO2_EMISSION",
                "CONDITION_DESCRIPTION",
                "COST_PER_UNIT_CURRENCY",
                "LEAD_TIME_DAYS",
                "MODE_OF_TRANSPORTATION",
                "CONDITION_CODE",
              ],
            },
          ],
        },
      },
      ERROR_WARNING_VIEW: {
        title: "ERRORS_WARNING",
        views: {
          dynamic_table: [
            {
              title: "Erros & warnings",
              data: {
                ERROR: "$.DATA.ERRORS.ERROR",
                INFO: "$.DATA.ERRORS.INFO",
                WARNING: "$.DATA.ERRORS.WARNING",
              },
              columns: {
                CODE: "$.CODE",
                DETAILS: "$.DETAILS.DATA",
                MODULE: "$.MODULE",
              },
              type: CATEGORY_VIEWS.ERROR_WARNING_VIEW,
            },
          ],
        },
      },

      OTHER_OPTIONS: true,
    },
  },

  {
    name: "SUBSTITUTION",
    category: {
      ACTION_DETAILS: {
        title: "ACTION_DETAILS",
        views: {
          inputs: [
            {
              title: "details",
              column: null,

              data: { ACTION_DETAILS: "$.DATA.ACTION_DETAILS" },
            },
          ],
        },
      },
      RISK_IMPACTS: {
        title: "RISK_IMPACT",
        views: {
          inputs: [
            {
              title: "Impact details",
              column: null,

              data: { IMPACT: "$.DATA.IMPACT" },
            },
            {
              title: "New Purchase Order",
              column: null,

              data: { NEW_PURCHASE_ORDER: "$.DATA.NEW_PURCHASE_ORDER" },
            },
          ],
        },
      },
      ACTION_CONTEXT_VIEW: {
        title: "Action_details",
        views: {
          flow_chart: [
            {
              title: "Purchase Order",

              type: "OBJECT",
              data: {
                PURCHASE_ORDER: "$.DATA.PURCHASE_ORDER",
              },
              startNodes: [
                {
                  path: {
                    SUPPLIER_CODE: `$.DATA.MASTER.SUPPLIER_MASTER.SUPPLIER_CODE`,
                  },
                  valueField: "SUPPLIER_CITY",
                },
                "SHIPPING_DATE",
              ],
              endNodes: ["RECEIVING_LOCATION_CODE", "DELIVERY_DATE"],
              details: ["ITEM_CODE", "QUANTITY", "LINE_NUMBER"],
            },
            {
              type: "OBJECT",
              title: "Purchase Order Conditions",
              // missing
              data: { PO_CONDITION: "$.DATA.PO_CONDITION" },

              startNodes: [
                {
                  path: {
                    SUPPLIER_CODE: `$.DATA.MASTER.SUPPLIER_MASTER.SUPPLIER_CODE`,
                  },
                  valueField: "SUPPLIER_CITY",
                },
              ],
              endNodes: [
                {
                  path: {
                    RECEIVING_LOCATION_CODE: `$.DATA.MASTER.LOCATION_MASTER.LOCATION_ID`,
                  },
                  valueField: "LOCATION_CITY",
                },
              ],

              details: [
                "CONDITION_CODE",
                //"CONDITION_DESCRIPTION",
                "LEAD_TIME_DAYS",
              ],
            },
          ],

          map: [
            {
              type: "OBJECT",
              title: "Shipping conditions",

              data: {
                PROCUREMENT_SHIPPING_CONDITION:
                  "$.DATA.PROCUREMENT_SHIPPING_CONDITION",
              },
              receiving_location: {
                RECEIVING_LOCATION_CODE: `$.DATA.MASTER.LOCATION_MASTER.LOCATION_ID`,
              },
              shipping_location: {
                SUPPLIER_CODE: `$.DATA.MASTER.SUPPLIER_MASTER.SUPPLIER_CODE`,
              },
              receiving_location_city: "LOCATION_CITY",
              shipping_location_city: "SUPPLIER_CITY",
              details: [
                "CO2_EMISSION",
                "CONDITION_DESCRIPTION",
                "COST_PER_UNIT_CURRENCY",
                "LEAD_TIME_DAYS",
                "TRANSPORTATION_MODE",
                "CONDITION_CODE",
              ],
            },
          ],
          inputs: [
            {
              title: " Substitution details",
              column: null,
              data: {
                PROCUREMENT_SUBSTITUTION: "$.DATA.PROCUREMENT_SUBSTITUTION",
              },
            },
            {
              title: "Updated Purchase Order",
              column: null,

              data: { UPDATED_PURCHASE_ORDER: "$.DATA.UPDATED_PURCHASE_ORDER" },
            },
          ],
        },
      },
      ERROR_WARNING_VIEW: {
        title: "ERRORS_WARNING",
        views: {
          dynamic_table: [
            {
              title: "Errors & Warnings",

              data: {
                ERROR: "$.DATA.ERRORS.ERROR",
                INFO: "$.DATA.ERRORS.INFO",
                WARNING: "$.DATA.ERRORS.WARNING",
              },
              columns: {
                CODE: "$.CODE",
                DETAILS: "$.DETAILS.DATA",
                MODULE: "$.MODULE",
              },
              type: CATEGORY_VIEWS.ERROR_WARNING_VIEW,
            },
          ],
        },
      },

      OTHER_OPTIONS: true,
    },
  },

  {
    name: "EXPRESS_DELIVERY",
    category: {
      ACTION_DETAILS: {
        title: "ACTION_DETAILS",
        views: {
          inputs: [
            {
              title: "details",
              column: null,

              data: { ACTION_DETAILS: "$.DATA.ACTION_DETAILS" },
            },
          ],
        },
      },
      RISK_IMPACTS: {
        title: "RISK_IMPACT",
        views: {
          inputs: [
            {
              title: "Impact details",
              column: null,

              data: { IMPACT: "$.DATA.IMPACT" },
            },
            {
              title: "Updated Purchase Order",
              column: null,

              data: { UPDATED_PURCHASE_ORDER: "$.DATA.UPDATED_PURCHASE_ORDER" },
            },
          ],
        },
      },
      ACTION_CONTEXT_VIEW: {
        title: "Action_details",
        views: {
          flow_chart: [
            {
              type: "OBJECT",
              title: "Purchase Order",
              data: { PURCHASE_ORDER: "$.DATA.PURCHASE_ORDER" },
              startNodes: [
                {
                  path: {
                    SUPPLIER_CODE: `$.DATA.MASTER.SUPPLIER_MASTER.SUPPLIER_CODE`,
                  },
                  valueField: "SUPPLIER_CITY",
                },
                "SHIPPING_DATE",
              ],
              endNodes: ["RECEIVING_LOCATION_CODE", "DELIVERY_DATE"],
              details: ["ITEM_CODE", "QUANTITY", "STANDARD_INBOUND_ROUTE"],
            },
            {
              type: "OBJECT",
              title: "Purchase Order condition",

              data: {
                PURCHASE_ORDER_CONDITION: "$.DATA.PURCHASE_ORDER_CONDITION",
              },
              startNodes: ["SUPPLIER_CODE"],
              endNodes: ["RECEIVING_LOCATION_CODE"],
              details: [
                "CONDITION_CODE",
                "CONDITION_DESCRIPTION",
                "LEAD_TIME_DAYS",
              ],
            },
          ],

          map: [
            {
              type: "OBJECT",
              title: "shipping conditions",

              data: {
                PROCUREMENT_SHIPPING_CONDITION:
                  "$.DATA.PROCUREMENT_SHIPPING_CONDITION",
              },
              receiving_location: {
                RECEIVING_LOCATION_CODE: `$.DATA.MASTER.LOCATION_MASTER.LOCATION_ID`,
              },
              shipping_location: {
                SUPPLIER_CODE: `$.DATA.MASTER.SUPPLIER_MASTER.SUPPLIER_CODE`,
              },
              receiving_location_city: "LOCATION_CITY",
              shipping_location_city: "SUPPLIER_CITY",
              details: [
                "CO2_EMISSION",
                "CONDITION_DESCRIPTION",
                "COST_PER_UNIT_CURRENCY",
                "LEAD_TIME_DAYS",
                "MODE_OF_TRANSPORTATION",
                "CONDITION_CODE",
              ],
            },
          ],
          inputs: [
            {
              title: "Purchase Order",
              column: null,

              data: { UPDATED_PURCHASE_ORDER: "$.DATA.UPDATED_PURCHASE_ORDER" },
            },
          ],
        },
      },
      ERROR_WARNING_VIEW: {
        title: "ERRORS_WARNING",
        views: {
          dynamic_table: [
            {
              title: "Errors & warnings",

              data: {
                ERROR: "$.DATA.ERRORS.ERROR",
                INFO: "$.DATA.ERRORS.INFO",
                WARNING: "$.DATA.ERRORS.WARNING",
              },
              columns: {
                CODE: "$.CODE",
                DETAILS: "$.DETAILS.DATA",
                MODULE: "$.MODULE",
              },
              type: CATEGORY_VIEWS.ERROR_WARNING_VIEW,
            },
          ],
        },
      },
      OTHER_OPTIONS: true,
    },
  },

  {
    name: "PARTIAL_DELIVERY",
    category: {
      ACTION_DETAILS: {
        title: "ACTION_DETAILS",
        views: {
          inputs: [
            {
              title: "details",
              column: null,

              data: { ACTION_DETAILS: "$.DATA.ACTION_DETAILS" },
            },
          ],
        },
      },
      RISK_IMPACTS: {
        title: "RISK_IMPACT",
        views: {
          inputs: [
            {
              title: "Impact details",
              column: null,

              data: { IMPACT: "$.DATA.IMPACT" },
            },
            {
              title: "New purchase order",
              column: null,

              data: { NEW_PURCHASE_ORDER: "$.DATA.NEW_PURCHASE_ORDER" },
            },
          ],
        },
      },
      ACTION_CONTEXT_VIEW: {
        title: "Action_details",
        views: {
          flow_chart: [
            {
              title: "Purchase Order",
              type: "OBJECT",
              data: { PURCHASE_ORDER: "$.DATA.PURCHASE_ORDER" },

              startNodes: [
                {
                  path: {
                    SUPPLIER_CODE: `$.DATA.MASTER.SUPPLIER_MASTER.SUPPLIER_CODE`,
                  },
                  valueField: "SUPPLIER_CITY",
                },
                "SHIPPING_DATE",
              ],
              endNodes: [
                {
                  path: {
                    RECEIVING_LOCATION_CODE: `$.DATA.MASTER.LOCATION_MASTER.LOCATION_ID`,
                  },
                  valueField: "LOCATION_CITY",
                },
                "DELIVERY_DATE",
              ],

              details: [
                "ITEM_CODE",
                "QUANTITY",

                //  "PURCHASE_COST_PER_UNIT",
                "LINE_NUMBER",
                //"STANDARD_INBOUND_ROUTE",
                // "UNIT_OF_MEASURE",
              ],
            },
          ],
          line_chart: [
            {
              title: "inventory & new inventory",
              type: "ARRAY",
              data: {
                INVENTORY: "$.DATA.INVENTORY",
                NEW_INVENTORY_PLAN: "$.DATA.NEW_INVENTORY_PLAN",
              },
              colorConfig: [
                { value: "INVENTORY", color: "#008BC7" },
                { value: "NEW_INVENTORY_PLAN", color: "#c70046" },
              ],
              xField: "DATE",
              yField: "ON_HAND_VALUE",
              multiple: true,
            },
          ],
          dualAxes_chart: [
            {
              title: "Inventory & New inventory plan",
              type: "ARRAY",
              dualAxis: true,
              dataSetY1: {
                data: {
                  INVENTORY: {
                    path: "$.DATA.INVENTORY",
                    seriesFieldY1: ["ON_HAND_VALUE", , "DEMAND"],
                  },
                  NEW_INVENTORY_PLAN: {
                    path: "$.DATA.NEW_INVENTORY_PLAN",
                    seriesFieldY1: ["ON_HAND_VALUE", "QUANTITY_ON_HAND"],
                  },
                },
                yTitle1: "Value",
              },
              dataSetY2: {
                data: {
                  INVENTORY: {
                    path: "$.DATA.INVENTORY",
                    seriesFieldY2: ["DAYS_OF_SUPPLY"], // can be more then one
                  },
                  NEW_INVENTORY_PLAN: {
                    path: "$.DATA.NEW_INVENTORY_PLAN",
                    seriesFieldY2: ["DAYS_OF_SUPPLY"],
                  },
                },

                yTitle2: "D.O.S",
              },
            },
          ],
          map: [
            {
              type: "OBJECT",
              title: "Shipping conditions map",
              data: {
                PROCUREMENT_SHIPPING_CONDITION:
                  "$.DATA.PROCUREMENT_SHIPPING_CONDITION",
              },
              receiving_location: {
                RECEIVING_LOCATION_CODE: `$.DATA.MASTER.LOCATION_MASTER.LOCATION_ID`,
              },
              shipping_location: {
                SUPPLIER_CODE: `$.DATA.MASTER.SUPPLIER_MASTER.SUPPLIER_CODE`,
              },
              receiving_location_city: "LOCATION_CITY",
              shipping_location_city: "SUPPLIER_CITY",
              details: [
                "CO2_EMISSION",
                "CONDITION_DESCRIPTION",
                "COST_PER_UNIT_CURRENCY",
                "LEAD_TIME_DAYS",
                "MODE_OF_TRANSPORTATION",
                "CONDITION_CODE",
              ],
            },
          ],
          inputs: [
            {
              title: "Updated Purchase Order",
              column: null,

              data: { UPDATED_PURCHASE_ORDER: "$.DATA.UPDATED_PURCHASE_ORDER" },
            },
          ],
        },
      },
      ERROR_WARNING_VIEW: {
        title: "ERRORS_WARNING",
        views: {
          dynamic_table: [
            {
              title: "Errors & warnings",
              data: {
                ERROR: "$.DATA.ERRORS.ERROR",
                INFO: "$.DATA.ERRORS.INFO",
                WARNING: "$.DATA.ERRORS.WARNING",
              },
              columns: {
                CODE: "$.CODE",
                DETAILS: "$.DETAILS.DATA",
                MODULE: "$.MODULE",
              },
              type: CATEGORY_VIEWS.ERROR_WARNING_VIEW,
            },
          ],
        },
      },

      OTHER_OPTIONS: true,
    },
  },
];

//    config={{
//     color_pallet: [
//       {
//         value: "",
//         color: "#008BC7",
//         id: 1721638845726,
//       },
//       {
//         value: "",
//         color: "#cd2b2b",
//         id: 1721638856029,
//       },
//     ],
//     pallet_type: "Pallet",
//     unit: "",
//     dual: false,
//   }}
//   fields={{
//     valueField: { value: "COUNT", type: "NUMBER" },
//     categoryField: [{ value: "ACTION.NAME", type: "STRING" }],
//     groupField: { value: "DUE_DATE", type: "DATE" },
//   }}
//   params={{
//     dateFormat: "DD$MM$YYYY",
//     numberFormat: "",
//     currency: "USD",
//     language: "fr-FR",
//   }}
//   showDrawer={showDrawer}}
// ];

// Inventory_Situation: [
//   { categoryField: "NEW_AVAILABLE_INVENTORY", dos: 5,value: 20000, groupeBy: "Available Stock" },
//   { categoryField: "NEW_AVAILABLE_INVENTORY", dos: 10,value: 157840, groupeBy: "Available Stock" },
//   { categoryField: "NEW_AVAILABLE_INVENTORY", dos: 15,value: 700000, groupeBy: "Available Stock" },

//   { categoryField: "CURRENT_AVAILABLE_INVENTORY", dos: 20, value: 477777,groupeBy: "Allocated Stock" },
//   { categoryField: "CURRENT_AVAILABLE_INVENTORY", dos: 23,value: 999999, groupeBy: "Allocated Stock" },
//   { categoryField: "CURRENT_AVAILABLE_INVENTORY", dos: 7, value: 2867888,groupeBy: "Allocated Stock" },

// ],

// "DATE_AT_ORIGIN": "2024-08-21T00",
//  "ORIGIN_LOCATION": "LOC-F001",
//   "CONTAINER_NUMBER": "1",
//   "TOTAL_CONTAINER_QUANTITY": 400,
//    "TOTAL_CONTAINER_VALUE": 1600,
//    "SUPPLIER_CODE": "SUP-F1"

//    "ORIGIN_LOCATION": "JEDDAH PORT",
//    "DATE_AT_ORIGIN": "2024-08-09T00:00:00",
//    "CONTAINER_NUMBER": "SUDU6122704",
//    "BOAT_NUMBER": "MAERSK KIEL V-248E",
//    "SUPPLIER_CODE": "LAMB WESTON / MEIJER VOF",
//    "TOTAL_CONTAINER_QUANTITY": 1660.0

//    "TOTAL_CONTAINER_VALUE": null

const datasE1 = [
  {
    categoryField: "CURRENT_AVAILABLE_INVENTORY",
    yField1: 13296,
    seriesFieldY1: "QUANTITY_ON_HAND",
    groupeField: "ITEM_CODE1",
  },
  {
    categoryField: "CURRENT_AVAILABLE_INVENTORY",
    yField1: 12217,
    seriesFieldY1: "QUANTITY_ON_HAND",
    groupeField: "ITEM_CODE2",
  },
  {
    categoryField: "CURRENT_AVAILABLE_INVENTORY",
    yField1: 15829,
    groupeField: "ITEM_CODE1",

    seriesFieldY1: "DEMAND",
  },
  {
    categoryField: "CURRENT_AVAILABLE_INVENTORY",
    yField1: 19573,
    groupeField: "ITEM_CODE2",

    seriesFieldY1: "DEMAND",
  },
  {
    categoryField: "NEW_AVAILABLE_INVENTORY",
    yField1: 14646,
    groupeField: "ITEM_CODE2",

    seriesFieldY1: "QUANTITY_ON_HAND",
  },
  {
    categoryField: "NEW_AVAILABLE_INVENTORY",
    yField1: 12817,
    groupeField: "ITEM_CODE1",

    seriesFieldY1: "QUANTITY_ON_HAND",
  },
];
