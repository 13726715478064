import { ProColumns } from "@ant-design/pro-components";
import { LicenseItem } from "../../../../interfaces/license";
import MenuOptions from "../../../common/menu/MenuOptions";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Tag } from "antd";
import { getTranslation } from "../../../../utils/transaltion";

export default function LicensesColumns(
  formatDate: string,
  viewLicenseModal: (record: any) => void,
  updateLicenseModal: (record: any) => void,
  deleteLicense: (id: string) => void,
  copilotsEnum: { [key: string]: { text: string } },
  orgaEnum: { [key: string]: { text: string } }
) {
  const { t } = useTranslation();
  const columns: ProColumns<LicenseItem>[] = [
    {
      title: t("ORGANIZATION"),
      dataIndex: "organization",
      valueType: "select",
      valueEnum: orgaEnum,
      fieldProps: {
        showSearch: true, // Enable search in filter
        optionFilterProp: "label", // Search by label
        filterOption: (input: any, option: any) =>
          option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0,
      },
      render: (text, record) => record?.organization?.name,
    },
    {
      title: t("COPILOTS"),
      dataIndex: "copilot",
      valueType: "select",
      valueEnum: copilotsEnum,
      fieldProps: {
        showSearch: true, // Enable search in filter
        optionFilterProp: "label", // Search by label
        filterOption: (input: any, option: any) =>
          option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0,
      },
      render: (text, record) => {
        return record?.copilots
          ?.map((copilot) => getTranslation(copilot.name, "COPILOTS_"))
          ?.join(", ");
      },
    },
    {
      title: getTranslation("STATUS", "labels"),
      dataIndex: "status",
      render: (text, record) => {
        const startDate = moment(record?.start).startOf("day");
        const expiryDate = moment(record?.expiry).endOf("day");
        const nowDate = moment().endOf("day");
        return (
          <>
            {expiryDate.isBefore(nowDate) ? (
              <Tag color="red" style={{ marginInlineStart: "8px" }}>
                {getTranslation("EXPIRED", "labels")}
              </Tag>
            ) : startDate.isBefore(nowDate) ? (
              <Tag
                style={{
                  marginInlineStart: "8px",
                  color: "rgb(38, 177, 77)",
                  borderColor: "rgb(38, 177, 77, 0.6)",
                  backgroundColor: "rgb(128, 200, 148, 0.12)",
                }}
              >
                {getTranslation("ACTIVE", "labels")}
              </Tag>
            ) : (
              <Tag color="blue" style={{ marginInlineStart: "8px" }}>
                {getTranslation("UPCOMING", "labels")}
              </Tag>
            )}
          </>
        );
      },
      search: false,
    },
    {
      title: getTranslation("START_DATE", "labels"),
      dataIndex: "start",
      valueType: "dateRange",
      render: (text, record) => {
        const start = new Date(record.start);
        return moment(start).format(formatDate);
      },
      search: {
        transform: (value) => {
          if (value) {
            const formattedStartDate = new Date(value[0]);
            const formattedSEndDate = new Date(value[1]);
            return {
              start: [
                formattedStartDate?.toISOString(),
                formattedSEndDate?.toISOString(),
              ],
            };
          }
        },
      },
    },
    {
      title: getTranslation("EXPIRY_DATE", "labels"),
      dataIndex: "expiry",
      valueType: "dateRange",
      align: "left",
      render: (text, record) => {
        return <>{moment(record?.expiry).format(formatDate)}</>;
      },
      search: {
        transform: (value) => {
          if (value) {
            const formattedStartDate = new Date(value[0]);
            const formattedSEndDate = new Date(value[1]);
            return {
              expiry: [
                formattedStartDate?.toISOString(),
                formattedSEndDate?.toISOString(),
              ],
            };
          }
        },
      },
    },
    {
      title: t("MORE_ACTIONS"),
      valueType: "option",
      key: "option",
      width: "10%",
      align: "center",
      render: (text, record) => (
        <span>
          <MenuOptions
            viewRecord={() => {
              viewLicenseModal(record);
            }}
            editRecord={() => {
              updateLicenseModal(record);
            }}
            deleteRecord={() => {
              deleteLicense(record?.id);
            }}
            title={t("errors:CONFIRM_DELETE_MESSAGE")}
            /* mode={moment(record?.start) < moment() && WORK_MODE.VIEW} */
          />
        </span>
      ),
    },
  ];
  return columns;
}
