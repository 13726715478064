import { ProTable } from "@ant-design/pro-components";
import { Button, Tooltip, Popover } from "antd";
import { t } from "i18next";
import { FiSearch } from "react-icons/fi";
import { getTranslation } from "../../../../../utils/transaltion";
import { DefineTableColumns } from "./TableRunning.def";
import { useContext, useState } from "react";
import { SharedContext } from "../../context/SharedContext";
import {
  SharedContextFunctions,
  useSharedContextFunctions,
} from "../../logic/runningShared.logic";

export const FilterButton = (props: any) => {
  const [loadingAccept, setLoadingAccept] = useState<boolean>(false);
  const [isFirstSearchClick, setIsFirstSearchClick] = useState(true);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const { filterRunningRisks } =
    useSharedContextFunctions() as SharedContextFunctions;

  const {
    copilotName,
    menu,
    setFilterparams,
    setSortingOrder,
    setSortingField,
    setSortingMenuActive,
    formRef,
  } = props;
  const { selectedRisk, setSelectedRisk } = useContext(SharedContext);
  const columns = DefineTableColumns({
    copilotName,
    menu,
    currentRiskId: selectedRisk?.id,
    setSelectedRisk,
    setLoadingAccept,
  });
  const handleSearchClick = () => {
    formRef.current?.submit();
  };
  return (
    <>
      {/* filter button */}
      <Button
        icon={
          <Tooltip title={t("SEARCH")}>
            <Popover
              placement="bottom"
              content={() => (
                <div>
                  <ProTable
                    style={{ padding: "-30px" }}
                    columns={columns}
                    request={(params): any => {
                      const filterArgs: {
                        [key: string]: string;
                      } = {};
                      const { current, pageSize, ...rest } = params;

                      Object.entries(rest).forEach(([key, value]) => {
                        try {
                          filterArgs[key] = JSON.parse(value);
                        } catch (e) {
                          filterArgs[key] = value;
                        }
                      });
                      if (!isFirstSearchClick) {
                        setFilterparams(filterArgs);
                        filterRunningRisks(filterArgs, {});
                        setPopoverVisible(false);
                      } else {
                        setIsFirstSearchClick(false);
                      }

                      return {};
                    }}
                    onReset={() => {
                      setFilterparams({});
                      setSortingOrder("desc");
                      setSortingField("created_at");
                      setSortingMenuActive(false);
                      filterRunningRisks({}, {});
                      setPopoverVisible(false);
                    }}
                    search={{
                      span: {
                        xs: 24,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                        xxl: 12,
                      },
                      filterType: "query",
                      searchText: getTranslation("APPLY", "labels"),
                      resetText: t("RESET_TEXT_BUTTON"),
                      className: "custom-search-form",
                      labelWidth: "auto",
                    }}
                    form={{
                      formRef,
                      onFinish: async (values) => {
                        handleSearchClick();
                        return true;
                      },
                    }}
                    tableRender={(_, dom) => <></>}
                  />
                </div>
              )}
              trigger={["click"]}
              open={popoverVisible}
              onOpenChange={(visible) => setPopoverVisible(visible)}
              overlayStyle={{
                background: "#fff",
                borderRadius: 4,
                maxWidth: "800px",
              }}
            >
              <FiSearch />
            </Popover>
          </Tooltip>
        }
        shape="default"
        onClick={() => setPopoverVisible(true)}
      />
    </>
  );
};
